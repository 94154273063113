import { useState, useEffect } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { toast } from "react-toastify";
import IMG from "../../../../../Assets/dashboard-img.webp";
import GaugeChart from "react-gauge-chart";

function StudentDashboard({ setRefresh }: any) {
  interface Dashboard {
    average_quiz_score: string;
    courses_data: {
      description: string;
      difficulty: string;
      id: string;
      level_description: string;
      level_id: string;
      level_name: string;
      name: string;
      price: string;
    };
    school_name: string;
    courses_number: string;
    student_level: string;
    student_level_name: string;
    max_input_tokens_per_day: number;
    max_output_tokens_per_day: number;
    input_tokens_today: number;
    output_tokens_today: number;
  }

  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [dashboardData, setDashboardData] = useState<Dashboard>();

  const [inputPercent, setInputPercent] = useState<number>(0);
  const [outputPercent, setOutputPercent] = useState<number>(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_DASHBOARD_DATA}`,
      setDashboardData
    );
  }, []);

  useEffect(() => {
    if (dashboardData?.input_tokens_today !== undefined) {
      setInputPercent(
        isNaN(
          (dashboardData?.input_tokens_today ?? 0) /
            (dashboardData?.max_input_tokens_per_day ?? 0)
        )
          ? 0
          : dashboardData?.input_tokens_today /
              dashboardData?.max_input_tokens_per_day
      );
    }
    if (dashboardData?.output_tokens_today !== undefined) {
      setOutputPercent(
        isNaN(
          (dashboardData?.output_tokens_today ?? 0) /
            (dashboardData?.max_output_tokens_per_day ?? 0)
        )
          ? 0
          : dashboardData?.output_tokens_today /
              dashboardData?.max_output_tokens_per_day
      );
    }
    setLoading(false);
  }, [dashboardData]);

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="grid grid-cols-10 mt-10">
        <div className=" flex flex-col justify-between col-span-3 p-4">
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#9665FF]">
              <div>
                You are enrolled in {dashboardData?.courses_number} courses!
              </div>
              <div className="w-full flex justify-center">
                <div
                  onClick={() => (window.location.href = "/courses")}
                  className="bg-white text-[#4D30B5] p-2 rounded-md text-[15px]  text-center w-fit mt-5 cursor-pointer mb-2 hover:bg-gray-200 duration-300"
                >
                  View Courses
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#9665FF]">
              You are on {dashboardData?.student_level_name}.
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#4AD4F0]">
              <div>Input Tokens Used:</div>
              <div>
                {dashboardData?.input_tokens_today}/
                {dashboardData?.max_input_tokens_per_day}
              </div>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={inputPercent}
              />
            </div>
          </div>
        </div>
        <div className=" col-span-4 p-10">
          <img src={IMG} alt="dashboard img" />
        </div>
        <div className=" flex flex-col justify-between col-span-3 p-4">
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#FF8144]">
              <div>
                You have an average quiz score of{" "}
                {dashboardData?.average_quiz_score} / 100
              </div>
              <div className="w-full flex justify-center">
                <div
                  onClick={() => (window.location.href = "/quizzes")}
                  className="bg-white text-[#4D30B5] p-2 rounded-md text-[15px]  text-center w-fit mt-5 cursor-pointer mb-2 hover:bg-gray-200 duration-300"
                >
                  View Quizzes
                </div>
              </div>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#FF8144]">
              you are registered to {dashboardData?.school_name} school.
            </div>
          </div>

          <div className=" flex flex-col items-center justify-center ">
            <div className="flex flex-col text-center justify-center font-semibold text-[20px] w-full p-2 rounded-lg text-white bg-[#F4B4D3]">
              <div>Output Tokens Used:</div>
              <div>
                {dashboardData?.output_tokens_today}/
                {dashboardData?.max_output_tokens_per_day}
              </div>
              <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={outputPercent}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudentDashboard;
