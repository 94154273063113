import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { RiSubtractFill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import { MenuItem, Select } from "@mui/material";
import { fetchData } from "../../../../GlobalFunctions";

function SAEditSubscription({ setRefreshUserData, userData }: any) {
  interface UsersData {
    id: string;
    name: string;
    level_name: string;
    level_id: string;
  }

  interface GradesData {
    name: string;
    id: string;
  }

  const { subscription_name } = useParams();
  const { state } = useLocation();

  const [subscriptionId, setSubscriptionId] = useState<string>(() => {
    const storedSubscriptionId =
      window.localStorage.getItem("subscriptionId") || "";
    return (state && state.subscription_id) || storedSubscriptionId;
  });

  const [contractSubscriptionId, setContractSubscriptionId] = useState<string>(
    () => {
      const storedContractSubscriptionId =
        window.localStorage.getItem("contractSubscriptionId") || "";
      return (
        (state && state.contract_subscription_id) ||
        storedContractSubscriptionId
      );
    }
  );

  const [schoolId, setSchoolId] = useState<string>(() => {
    const storedSchoolId = window.localStorage.getItem("schoolId") || "";
    return (state && state.school_id) || storedSchoolId;
  });

  const [usersData, setUsersData] = useState<UsersData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UsersData[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [tabChange, setTabChange] = useState("student");
  const [selectedGrade, setSelectedGrade] = useState("all");
  const [gradesData, setGradesData] = useState<GradesData[]>([]);

  useEffect(() => {
    setFilteredUsers([]);
    setUsersData([]);
    setSelectedUsers([]);
    setSelectedGrade("all");

    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append(
          "school_id",
          schoolId === "" ? userData?.school_id : schoolId
        );
        formData.append("subscription_id", subscriptionId);
        formData.append("school_subscription_id", contractSubscriptionId);
        formData.append("type", tabChange);

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_STUDENTS_BY_SUBSCRIPTION_ID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setSelectedUsers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    async function fetchData2() {
      try {
        const formData = new FormData();
        formData.append(
          "school_id",
          schoolId === "" ? userData?.school_id : schoolId
        );
        formData.append("subscription_id", subscriptionId);
        formData.append("school_subscription_id", contractSubscriptionId);
        formData.append("type", tabChange);

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_SUBSCRIPTION_STUDENTS_BY_SCHOOL_ID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setUsersData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCOOLS_FOR_FILTER}`,
      setGradesData
    );
    fetchData1();
    fetchData2();
  }, [refresh, tabChange]);

  console.log(usersData);

  useEffect(() => {
    if (selectedGrade === "all") {
      const filtered = usersData
        .filter((user) =>
          user.name.toLowerCase().includes(usersSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      setFilteredUsers(filtered);
    } else {
      const filtered = usersData
        .filter(
          (user) =>
            user.name.toLowerCase().includes(usersSearchTerm.toLowerCase()) &&
            (selectedGrade ? user.level_name === selectedGrade : true)
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      setFilteredUsers(filtered);
    }
  }, [usersData, usersSearchTerm, selectedGrade]);

  const handleUserSelect = (id: string) => {
    setSelectedUsers((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((userId) => userId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedUsers((prevIds) => {
      const newSelectedIds = [...prevIds];

      filteredUsers.forEach((user) => {
        if (!newSelectedIds.includes(user.id)) {
          newSelectedIds.push(user.id);
        }
      });

      return newSelectedIds;
    });
  };

  const handleDeSelectAll = () => {
    setSelectedUsers((prevIds) => {
      return prevIds.filter(
        (id) => !filteredUsers.some((user) => user.id === id)
      );
    });
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("subscription_id", subscriptionId);
    formData.append("school_subscription_id", contractSubscriptionId);
    formData.append(
      "school_id",
      schoolId === "" ? userData?.school_id : schoolId
    );
    formData.append("user_ids", JSON.stringify(selectedUsers));
    formData.append("type", tabChange);

    try {
      let endpoint = `${process.env.REACT_APP_ASSIGN_SUBSCRIPTION_STUDENTS}`;
      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
      setRefresh((prevRefresh: any) => prevRefresh + 1);

      // window.location.href = `/classes`;
      toast.success("Subscription has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An unexpected error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute">
          <Link to={"/school_subscriptions"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="subscription" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          {subscription_name}
        </p>
        <div className="flex flex-row gap-2 w-full py-2">
          <div
            className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
              tabChange === "student"
                ? "bg-[#8462ff] text-white"
                : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
            }`}
            onClick={() => {
              setTabChange("student");
            }}
          >
            Students
          </div>
          {/* <div
            className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
              tabChange === "grade"
                ? "bg-[#8462ff] text-white"
                : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
            }`}
            onClick={() => {
              setTabChange("grade");
            }}
          >
            Grades
          </div> */}
          <div
            className={`p-2 px-8 duration-300 rounded-md cursor-pointer ${
              tabChange === "employee"
                ? "bg-[#8462ff] text-white"
                : "bg-[#4D30B5] text-white hover:bg-[#7257d3]"
            }`}
            onClick={() => {
              setTabChange("employee");
            }}
          >
            Employees
          </div>
        </div>
        <form className="w-full" onSubmit={handleEditSubmit}>
          <div className="p-4 flex flex-col gap-4">
            <div
              className="flex flex-col"
              style={{ maxHeight: "calc(100vh - 310px)" }}
            >
              <div>
                <div className="grid grid-cols-3 gap-5 mb-5">
                  <div className=" flex flex-col  ">
                    <label className="pb-2 text-[20px]">Name:</label>
                    <input
                      type="text"
                      placeholder="Search by name"
                      value={usersSearchTerm}
                      onChange={(e) => setUsersSearchTerm(e.target.value)}
                      className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
                    />
                  </div>
                  {tabChange === "student" && (
                    <div className=" flex flex-col  ">
                      <label className="pb-2 text-[20px]">Grade:</label>
                      <Select
                        value={selectedGrade}
                        onChange={(e) => setSelectedGrade(e.target.value)}
                        className=" "
                      >
                        <MenuItem id="tone-div" key="all" value="all">
                          All
                        </MenuItem>
                        {gradesData &&
                          gradesData.map((grade, index) => (
                            <MenuItem
                              id="tone-div"
                              key={index}
                              value={grade.name}
                            >
                              {grade.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-2 content-center text-center h-full">
                <label className="pb-2 text-[20px] mt-1">
                  {filteredUsers.length > 0 &&
                  filteredUsers.every((user) => selectedUsers.includes(user.id))
                    ? "Remove All:"
                    : "Add All:"}
                </label>
                <div className="flex flex-col justify-center">
                  {filteredUsers.length > 0 &&
                  filteredUsers.every((user) =>
                    selectedUsers.includes(user.id)
                  ) ? (
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleDeSelectAll();
                      }}
                      className=" bg-red-600 hover:bg-red-800 text-white p-1 rounded duration-300"
                    >
                      <RiSubtractFill />
                    </button>
                  ) : (
                    <button
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleSelectAll();
                      }}
                      className=" bg-green-600 hover:bg-green-800 text-white p-1 rounded duration-300  "
                    >
                      <IoMdAdd />
                    </button>
                  )}
                </div>
              </div>
              <div className="rounded-lg w-full mt-2 overflow-scroll">
                {filteredUsers && filteredUsers.length > 0 && (
                  <table className="w-full">
                    <thead className="bg-black text-white">
                      <tr>
                        <th className="w-1/6 text-left p-2">ID</th>
                        <th className="w-2/6 text-left p-2">Name</th>
                        <th className="w-2/6 text-left p-2">Grade</th>
                        <th className="w-1/6 text-left p-2">Action</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-black border-black border-b-4 ">
                      {filteredUsers.map(
                        (user, index) =>
                          selectedUsers.includes(user.id) && (
                            <tr key={user.id}>
                              <td className="p-2">{user.id}</td>
                              <td className="p-2">{user.name}</td>
                              <td className="p-2">{user.level_name}</td>

                              <td className="p-2">
                                <button
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    handleUserSelect(user.id);
                                  }}
                                  className=" bg-red-600 hover:bg-red-800 text-white p-1 rounded duration-300"
                                >
                                  <RiSubtractFill />
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                    <tbody className="divide-y divide-black ">
                      {filteredUsers.map(
                        (user, index) =>
                          !selectedUsers.includes(user.id) && (
                            <tr key={user.id}>
                              <td className="p-2">{user.id}</td>
                              <td className="p-2">{user.name}</td>
                              <td className="p-2">{user.level_name}</td>
                              <td className="p-2">
                                <button
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    handleUserSelect(user.id);
                                  }}
                                  className=" bg-green-600 hover:bg-green-800 text-white p-1 rounded duration-300"
                                >
                                  <IoMdAdd />
                                </button>
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <div className=" flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default SAEditSubscription;
