import { AnimatePresence, motion } from "framer-motion";
import Okation from "../../Assets/OkationLogo.png";
import boxMind from "../../Assets/boxMind_wide.png";
import AI from "../../Assets/AI.jpg";
import { useState } from "react";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../authSlice";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { BiSolidShow, BiSolidHide } from "react-icons/bi";

interface LoginResponse {
  message: string;
  access_token: string;
  user: {
    user_name: string;
    user_email: string;
    user_id: string;
    roles: [string];
  };
}

function SignIn({ setUserID }: any) {
  const [user_identifier, setUserIdentifier] = useState("");
  const [user_password, setUserPassword] = useState("");
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response: AxiosResponse<LoginResponse> = await axios.post(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_LOGIN}`,
        {
          user_identifier,
          user_password,
        }
      );

      const { access_token } = response.data;

      const user_name = response.data.user.user_name;
      // const user_email = response.data.user.user_email;
      // const user_role = response.data.user.roles;
      setUserID(response.data.user.user_id);
      localStorage.setItem("user_id", response.data.user.user_id);
      localStorage.setItem("user_email", response.data.user.user_email);
      Cookies.set("user_id", response.data.user.user_id, {
        expires: 1,
      });

      // console.log(user_role);

      localStorage.setItem("user_name", user_name);
      // localStorage.setItem("user_email", user_email);
      // localStorage.setItem("user_role", user_role);

      Cookies.set("access_token", access_token, {
        expires: 1,
        // secure: true,
        // sameSite: "Strict",
        // httpOnly: true,
      });

      Cookies.set("selected_source", "", { expires: 1 });

      dispatch(login(access_token));

      // console.log(access_token, "Access_Token");

      // console.log("Login successful", response.data);
      if (response.data.user.roles.includes("student")) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message || error.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        // console.error("Signup failed", error);
        if (error.response?.data) {
          console.log("Error response data:", error.response.data);
        }
      } else {
        toast.error("An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        console.error("Signin failed", error);
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <section className=" lg:grid grid-flow-row-dense grid-cols-10 ">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.7 }}
          className=" w-full col-span-3"
        >
          <form
            onSubmit={handleSubmit}
            className=" w-full flex flex-col justify-center items-center mx-auto h-screen bg-gray-200"
          >
            <div className="my-6">
              <img src={boxMind} width={220} alt="boxMind" loading="lazy" />
            </div>
            <div className=" flex flex-col gap-4 ">
              <p className=" flex justify-center text-[40px] font-bold pb-[50px] text-[#135991]">
                Sign In
              </p>
              <div className=" pb-[50px] flex flex-col gap-5">
                <div className=" flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Email/Username</p>
                  <input
                    type="text"
                    name="user_identifier"
                    placeholder="Email or Username"
                    value={user_identifier}
                    onChange={(e) => setUserIdentifier(e.target.value)}
                    className=" border p-2 outline-none rounded-lg"
                  />
                </div>
                <div className=" relative flex flex-row justify-between items-center gap-5 text-[#135991]">
                  <p className=" font-bold">Password</p>
                  <input
                    type={isVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={user_password}
                    onChange={(e) => setUserPassword(e.target.value)}
                    className=" border p-2 outline-none rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                    className="outline-none text-[#135991] absolute right-2"
                  >
                    {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className=" bg-[#135991] hover:bg-[#062c47] duration-300 p-4 rounded-lg text-white font-semibold "
              >
                Sign In
              </button>
              <div className=" flex flex-col items-center justify-center pt-3 ">
                {/* <div className=" flex flex-row font-bold text-[12px] gap-0.5">
                  <span className=" text-[#135991]">
                    {" "}
                    Don't have an Account?
                  </span>{" "}
                  <a
                    href="/signup"
                    className=" text-blue-700 underline hover:text-blue-800 duration-300"
                  >
                    Sign Up
                  </a>
                </div> */}
                <div className="flex flex-row items-center gap-2 pt-24">
                  <span className=" text-[#135991] font-semibold">
                    Powered by
                  </span>{" "}
                  <a
                    href="https://boxmind.ai/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Okation}
                      width={40}
                      alt="Okation"
                      className=" flex justify-center items-center"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </form>
        </motion.div>
      </AnimatePresence>
      <div className=" col-span-7 w-full lg:h-screen max-lg:hidden">
        <img src={AI} alt="AI" className="w-full h-full" loading="lazy" />
      </div>
    </section>
  );
}

export default SignIn;
