import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PublicRoute = () => {
  return Cookies.get("access_token") !== undefined ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
