import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { Interweave } from "interweave";
import { FaRegStar, FaStar } from "react-icons/fa";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import api from "../../../../api";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

function ViewCourse({ setRefresh, userData }: any) {
  interface CourseData {
    id: string;
    name: string;
    difficulty: string;
    description: string;
    image: string;
    rating: number;
    comment: string;
  }
  interface ChaptersData {
    id: string;
    title: string;
    description: string;
    image: string;
    allow_open_course: boolean;
  }

  const { course_name } = useParams();
  const [courseData, setCourseData] = useState<CourseData>();
  const [chaptersData, setChaptersData] = useState<ChaptersData[]>([]);
  const [filteredChaptersData, setFilteredChaptersData] = useState<
    ChaptersData[]
  >([]);

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [gradeId, setGradeId] = useState<string>(() => {
    const storedGradeId = window.localStorage.getItem("gradeId") || "";
    return (state && state.course_grade) || storedGradeId;
  });
  const user_id = localStorage.getItem("user_id") || "default_user";

  const [refreshChapters, setRefreshChapters] = useState(0);

  const [rating, setRating] = useState<number>(0);
  const [comment, setComment] = useState<string>("");

  const [hoverRating, setHoverRating] = useState<number>(0);

  const handleMouseEnter = (value: number) => {
    setHoverRating(value);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_COURSES_BY_ID}${courseId}`,
      setCourseData
    );
    setLoading1(false);
  }, []);

  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();

        formData.append("level_id", gradeId);

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTERS_BY_COURSE_ID}${courseId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setChaptersData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData1();
    setLoading2(false);
  }, [user_id, refreshChapters]);

  useEffect(() => {
    setRating(courseData?.rating ?? 0);
    setComment(courseData?.comment ?? "");
  }, [courseData]);

  useEffect(() => {
    const filtered = chaptersData
      .filter((chapter) => chapter.title.toLowerCase())
      .sort((a, b) => a.title.localeCompare(b.title));
    setFilteredChaptersData(filtered);
  }, [chaptersData]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      if (rating < 1) {
        toast.error(`Select Rating!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      formData.append("course_id", courseId);
      formData.append("user_id ", user_id);
      formData.append("rating", rating.toString());
      formData.append("comment", comment);

      await api.post(`${process.env.REACT_APP_COURSE_RATING}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshChapters((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      toast.success("Rating has been submitted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while submitting the rating: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error(
          "An unexpected error occurred while submitting the rating",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      }
      console.error("Error submitting the rating:", error);
    }
  };

  const handleChangeChapterOpen = async (
    e: any,
    chapter_id: string,
    allow_open_course: boolean
  ) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("chapter_id", chapter_id);
      formData.append("school_id", userData.school_id);
      formData.append("allow_open_course", String(allow_open_course));
      formData.append("level_id", gradeId);

      await api.post(
        `${process.env.REACT_APP_UPDATE_ALLOW_OPEN_COURSE}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshChapters((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      toast.success("Allow Open Chapter has been updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while updating the chapter: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while updating the chapter", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error updating the chapter:", error);
    }
  };

  if (loading1) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center gap-1 mb-2 w-full">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={"/courses"}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px] w-full">
          {userData.roles.includes("superadmin")
            ? courseData?.name
            : courseData?.name.toLowerCase().includes("ai")
            ? "AI And Robotics - Part I"
            : "AI And Robotics - Part II"}
          :
        </p>
      </div>

      <div
        className="relative h-screen"
        style={{ maxHeight: "calc(100vh - 140px)" }}
      >
        <div
          className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
          style={{ maxHeight: "calc(100vh - 140px)" }}
        >
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px] font-bold text-[#4D30B5]">
              About The Course:
            </label>
            <div
              className="course-description"
              dangerouslySetInnerHTML={{
                __html: courseData?.description as string,
              }}
            />
          </div>
          <div className="flex flex-row gap-3 ">
            <label
              className="pb-2 text-[20px] font-bold text-[#4D30B5]"
              htmlFor="english"
            >
              Difficulty:
            </label>
            <div className="flex flex-row content-center gap-2 ">
              {courseData?.difficulty === "1" && (
                <>
                  <div className="text-[#4D30B5]">
                    <svg
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                    >
                      <g
                        clipPath="url(#icon-level-introductory_svg__a)"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          d="M21 23V5h4v18h-4zM12 23V11h4v12h-4z"
                          strokeOpacity="0.5"
                        ></path>
                        <path d="M3 23v-8h4v8H3z"></path>
                      </g>
                      <defs>
                        <clipPath id="icon-level-introductory_svg__a">
                          <path fill="currentColor" d="M0 0h28v28H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-[#4D30B5] text-[18px] capitalize">
                    Beginner
                  </div>
                </>
              )}
              {courseData?.difficulty === "2" && (
                <>
                  <div className="text-[#4D30B5]">
                    <svg
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                    >
                      <g
                        clipPath="url(#icon-level-intermediate_svg__a)"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M21 23V5h4v18h-4z" strokeOpacity="0.5"></path>
                        <path d="M12 23V11h4v12h-4zM3 23v-8h4v8H3z"></path>
                      </g>
                      <defs>
                        <clipPath id="icon-level-intermediate_svg__a">
                          <path fill="currentColor" d="M0 0h28v28H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-[#4D30B5] text-[18px] capitalize">
                    Intermediate
                  </div>
                </>
              )}
              {courseData?.difficulty === "3" && (
                <>
                  <div className="text-[#4D30B5]">
                    <svg
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                    >
                      &gt;
                      <g
                        clipPath="url(#icon-level-advanced_svg__a)"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M21 23V5h4v18h-4zM12 23V11h4v12h-4zM3 23v-8h4v8H3z"></path>
                      </g>
                      <defs>
                        <clipPath id="icon-level-advanced_svg__a">
                          <path fill="currentColor" d="M0 0h28v28H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="text-[#4D30B5] text-[18px] capitalize">
                    Advanced
                  </div>
                </>
              )}
            </div>
          </div>
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px] font-bold text-[#4D30B5]">
              Sessions:
            </label>
            {filteredChaptersData && loading2 === false ? (
              filteredChaptersData.length > 0 ? (
                <div className=" grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
                  {filteredChaptersData?.map((chapter, index) => (
                    <div
                      key={index}
                      className={`relative flex flex-col lg:grid lg:grid-cols-12 mb-10 rounded-lg duration-300 bg-[#EDEAF8] lg:max-h-[420px]`}
                    >
                      <div className="col-span-4 flex flex-row gap-4 justify-center items-center bg-gray-200 ">
                        <img
                          src={`data:image/jpg;base64,${chapter.image}`}
                          alt=""
                          loading="lazy"
                          className="max-h-[420px] w-full h-full object-cover"
                        />
                      </div>
                      <div className=" col-span-8  p-8 max-h-[420px] flex flex-col justify-between">
                        <div className="flex flex-col lg:flex-row justify-between content-center">
                          <p className=" text-[30px] font-bold">
                            {chapter.title}
                          </p>
                          {userData?.roles.some((role: string) =>
                            ["teacher", "schooladmin"].includes(role)
                          ) && (
                            <div className="flex flex-row items-center gap-2 max-lg:mb-4">
                              <div className=" text-[18px] font-bold">
                                Allow View Session:
                              </div>
                              <button
                                type="button"
                                onClick={(e: any) => {
                                  handleChangeChapterOpen(
                                    e,
                                    chapter.id,
                                    chapter.allow_open_course
                                  );
                                }}
                                className="outline-none text-[#135991] duration-300"
                              >
                                {chapter.allow_open_course ? (
                                  <BiSolidShow size={22} />
                                ) : (
                                  <BiSolidHide size={22} />
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="text-gray-600 overflow-auto max-h-[250px]">
                          <div
                            className="course-description"
                            dangerouslySetInnerHTML={{
                              __html: chapter.description as string,
                            }}
                          />
                        </div>
                        {userData.roles.includes("student") ? (
                          chapter.allow_open_course && (
                            <div className=" mt-12 gap-2">
                              <Link
                                state={{
                                  chapter_id: chapter.id,
                                  course_id: courseId,
                                }}
                                to={`/courses/view-course/${course_name}/view-chapter/${chapter.title}`}
                                key={index}
                                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                              >
                                View Session
                              </Link>
                            </div>
                          )
                        ) : (
                          <div className=" mt-12 gap-2">
                            <Link
                              state={{
                                chapter_id: chapter.id,
                                course_id: courseId,
                                course_grade: gradeId,
                              }}
                              to={`/courses/view-course/${course_name}/view-chapter/${chapter.title}`}
                              key={index}
                              className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                            >
                              View Session
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex justify-center">
                  <ImSpinner size={30} className=" animate-spin" />
                </div>
              )
            ) : (
              <div className="flex justify-center">
                <ImSpinner size={30} className=" animate-spin" />
              </div>
            )}
          </div>
          <form onSubmit={handleUploadSubmit} className="my-10">
            <div className="flex flex-row gap-5 justify-center">
              <div className="flex flex-col justify-center items-center">
                <label className="pb-2 text-[20px] font-bold text-[#4D30B5] content-center">
                  Rate the course!
                </label>
                <div className="  flex flex-row gap-2 border border-black-200 w-fit p-2 rounded ">
                  <span
                    className=" cursor-pointer duration-300"
                    onClick={() => setRating(1)}
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {rating > 0 ? (
                      <FaStar color="gold" title="Profile" size={30} />
                    ) : hoverRating > 0 ? (
                      <FaStar
                        className=" opacity-25"
                        color="gold"
                        title="Profile"
                        size={30}
                      />
                    ) : (
                      <FaRegStar color="gold" title="Profile" size={30} />
                    )}
                  </span>
                  <span
                    className=" cursor-pointer duration-300"
                    onClick={() => setRating(2)}
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {rating > 1 ? (
                      <FaStar color="gold" title="Profile" size={30} />
                    ) : hoverRating > 1 ? (
                      <FaStar
                        className=" opacity-25"
                        color="gold"
                        title="Profile"
                        size={30}
                      />
                    ) : (
                      <FaRegStar color="gold" title="Profile" size={30} />
                    )}
                  </span>
                  <span
                    className=" cursor-pointer duration-300"
                    onClick={() => setRating(3)}
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {rating > 2 ? (
                      <FaStar color="gold" title="Profile" size={30} />
                    ) : hoverRating > 2 ? (
                      <FaStar
                        className=" opacity-25"
                        color="gold"
                        title="Profile"
                        size={30}
                      />
                    ) : (
                      <FaRegStar color="gold" title="Profile" size={30} />
                    )}
                  </span>
                  <span
                    className=" cursor-pointer duration-300"
                    onClick={() => setRating(4)}
                    onMouseEnter={() => handleMouseEnter(4)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {rating > 3 ? (
                      <FaStar color="gold" title="Profile" size={30} />
                    ) : hoverRating > 3 ? (
                      <FaStar
                        className=" opacity-25"
                        color="gold"
                        title="Profile"
                        size={30}
                      />
                    ) : (
                      <FaRegStar color="gold" title="Profile" size={30} />
                    )}
                  </span>
                  <span
                    className=" cursor-pointer duration-300"
                    onClick={() => setRating(5)}
                    onMouseEnter={() => handleMouseEnter(5)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {rating > 4 ? (
                      <FaStar color="gold" title="Profile" size={30} />
                    ) : hoverRating > 4 ? (
                      <FaStar
                        className=" opacity-25"
                        color="gold"
                        title="Profile"
                        size={30}
                      />
                    ) : (
                      <FaRegStar color="gold" title="Profile" size={30} />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className=" flex flex-col gap-2 w-full mt-5">
              <label className="pb-2 text-[20px] font-bold text-[#4D30B5] content-center">
                Leave a comment:
              </label>
              <textarea
                required
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg h-40 flex-grow"
              />
            </div>
            <div className=" flex justify-center  w-full mt-10 ">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ViewCourse;
