import Cookies from "js-cookie";
import { CgProfile } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { TbLogout } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../../authSlice";
import { useNavigate } from "react-router";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

const Profile = ({ userData }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("access_token");
    Cookies.remove("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_id");
    localStorage.removeItem("courseId");
    localStorage.removeItem("chapterId");
    dispatch(logout());
    navigate("/signin");
  };

  return (
    <section>
      <h1 className=" text-[#252733] text-[22px] py-4">Profile Settings</h1>
      <div className=" flex flex-col gap-4">
        <div className=" bg-white shadow-lg rounded-lg">
          <div className=" gap-2 py-10 flex flex-col items-center">
            <div>
              {userData?.profile_picture ? (
                <AsyncImage
                  className="rounded-full w-[100px] h-[100px] object-cover"
                  src={`data:image/jpg;base64,${userData?.profile_picture}`}
                  alt={userData?.user_name}
                  Transition={Blur}
                  style={{ width: 80, height: 80 }}
                  loading="lazy"
                />
              ) : (
                <CgProfile size={50} />
              )}
            </div>
            <p className=" text-[#252733] text-[24px]">{userData?.user_name}</p>
            <p className=" text-[#9B9CA1] text-[16px] font-normal">
              {userData?.user_email}
            </p>
          </div>
        </div>
        {/* <Link to={"/profile/upgrade-to-pro"}>
          <div className=" bg-gradient-to-r from-[#5130B6] via-[#a630b6e7] to-[#C61DBB] rounded-lg">
            <div className=" flex flex-row items-center justify-between p-4">
              <div className=" flex flex-row items-center gap-4">
                <div>
                  <img src={Star} alt="Star" width={82} loading="lazy" />
                </div>
                <div className=" flex flex-col gap-2 text-white">
                  <p className=" text-[24px]">Upgrade To Pro</p>
                  <p className=" text-[12px]">
                    Enjoy All The Benefits Without The Restrictions
                  </p>
                </div>
              </div>
              <div>
                <IoIosArrowForward
                  size={30}
                  color="white"
                  className=" hover:-mr-1 duration-300 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </Link> */}
        <h1 className="text-[#252733] text-[16px] py-1 font-semibold">
          General
        </h1>
        <div className=" flex flex-col gap-3">
          <Link to={"/profile/profile-settings"}>
            <div className=" bg-white rounded-lg">
              <div className=" flex flex-row items-center justify-between p-4">
                <div className=" flex flex-row items-center gap-4">
                  <div className=" bg-[#FBE8F8] p-2 rounded-lg">
                    <CgProfile size={25} color="#D51ABB" />
                  </div>
                  <p className=" font-semibold text-[16px]">Profile</p>
                </div>
                <div>
                  <IoIosArrowForward
                    size={30}
                    color="black"
                    className=" hover:-mr-1 duration-300 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </Link>
          <div
            onClick={handleLogout}
            role="button"
            className=" bg-white rounded-lg cursor-pointer"
          >
            <div className=" flex flex-row items-center justify-between p-4">
              <div className=" flex flex-row items-center gap-4">
                <div className=" bg-[#FFF0EE] p-2 rounded-lg text-[#FF6456]">
                  <TbLogout size={25} />
                </div>
                <button className="text-[#FF6456] font-semibold text-[16px]">
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
