const config = {
  readonly: false,
  toolbar: true,
  toolbarSticky: true,
  css: `
      ul {
        list-style-type: disc;
        margin-left: 20px;
      }
      ol {
        list-style-type: decimal;
        margin-left: 20px;
      }
      li {
        margin-bottom: 5px;
      }
      h1 {
        font-size: 2em;
        margin: 20px 0 10px;
        font-weight: bold;
      }
      h2 {
        font-size: 1.75em;
        margin: 18px 0 9px;
        font-weight: bold;
      }
      h3 {
        font-size: 1.5em;
        margin: 16px 0 8px;
        font-weight: bold;
      }
      h4 {
        font-size: 1.25em;
        margin: 14px 0 7px;
        font-weight: bold;
      }
      h5 {
        font-size: 1em;
        margin: 12px 0 6px;
        font-weight: bold;
      }
      h6 {
        font-size: 0.875em;
        margin: 10px 0 5px;
        font-weight: bold;
      }
      p {
        margin: 10px 0;
      }
      blockquote {
        margin: 10px 20px;
        padding: 10px;
        border-left: 5px solid #ccc;
        font-style: italic;
      }
      pre {
        background-color: #f5f5f5;
        padding: 10px;
        overflow-x: auto;
      }
    `,
  buttons: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "fontsize",
    "paragraph",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "link",
    "image",
    "|",
    "table",
    "|",
    "align",
    "undo",
    "redo",
  ],
  buttonsMD: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "fontsize",
    "paragraph",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "link",
    "image",
    "|",
    "table",
    "|",
    "align",
    "undo",
    "redo",
  ],
  buttonsXS: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "fontsize",
    "paragraph",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "link",
    "image",
    "|",
    "table",
    "|",
    "align",
    "undo",
    "redo",
  ],
  buttonsSM: [
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "fontsize",
    "paragraph",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "link",
    "image",
    "|",
    "table",
    "|",
    "align",
    "undo",
    "redo",
  ],
  placeholder: "",
  uploader: {
    insertImageAsBase64URI: true,
  },

  events: {
    beforeImageUpload: function (files: any) {
      const file = files[0];
      const fileType = file.type;
      const validTypes = ["image/png", "image/jpeg"];

      if (!validTypes.includes(fileType)) {
        alert("Only PNG and JPG images are allowed.");
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        //@ts-ignore
        const image = e.target.result;
        //@ts-ignore
        editor.current.selection.insertHTML(`<img src="${image}" alt="img"/>`);
      };
      reader.readAsDataURL(file);
      return false;
    },
  },
};

export default config;
