import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import { MenuItem, Select } from "@mui/material";
import { fetchData } from "../../../../GlobalFunctions";

function AddUser({
  handleUserClick,
  closeUser,
  setRefresh,
  setRefreshUserData,
  userData,
  refresh,
}: any) {
  interface RolesData {
    role_name: string;
    role_id: string;
  }

  interface SchoolsData {
    name: string;
    id: string;
  }

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [selectedRole, setSelectedRole] = useState("");
  const [rolesData, setRolesData] = useState<RolesData[]>([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);

  useEffect(() => {
    if (userData.roles.includes("superadmin")) {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
        setSchoolsData
      );
    }

    fetchData(`${process.env.REACT_APP_ROLES_DETAILS}`, setRolesData);
  }, [refresh, setRefreshUserData]);

  useEffect(() => {
    if (rolesData.length > 0) {
      if (
        userData?.roles?.includes("schooladmin") &&
        rolesData.some((role) => role.role_id === "29")
      ) {
        setSelectedRole("29");
      } else {
        setSelectedRole(rolesData[0]?.role_id || ""); // Default to the second role or an empty string
      }
    }
  }, [rolesData, userData]);

  useEffect(() => {
    if (userData?.roles?.includes("schooladmin")) {
      setSelectedSchool(userData.school_id);
    } else if (schoolsData.length > 0) {
      setSelectedSchool(schoolsData[0].id);
    }
  }, [schoolsData, userData]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.{8,})/;

    if (!passwordPattern.test(password)) {
      toast.error(
        "Password must contain at least 1 capital letter, 1 small letter, 1 special character, 1 number, and be at least 8 characters long.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        }
      );
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (email !== "") {
      if (!emailPattern.test(email)) {
        toast.error("Email format is not valid", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      }
    }

    const formData = new FormData();
    formData.append("username", name);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    if (dateOfBirth !== undefined) {
      formData.append("date_of_birth", dateOfBirth);
    }
    formData.append("user_email", email);
    formData.append("user_password", password);
    formData.append("school_id", selectedSchool);
    formData.append("role_id", selectedRole);

    try {
      await api.post(`${process.env.REACT_APP_CREATE_USER}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
      closeUser();
      toast.success("User has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        toast.error("An error occurred while creating the User", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
      onMouseDown={handleUserClick}
      role="button"
    >
      <div className="relative bg-white p-4 rounded shadow-2xl h-[90%] overflow-scroll">
        <button
          onClick={closeUser}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] p-1 duration-300 rounded-lg text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create User</p>

        <form onSubmit={handleSubmit} className=" md:w-[750px] w-[350px]">
          <div className=" p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-2">
              <div className=" flex flex-col  ">
                <p className=" text-[20px]">First Name:</p>
                <input
                  required
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-2 rounded-lg"
                />
              </div>
              <div className=" flex flex-col  ">
                <p className=" text-[20px]">Last Name:</p>
                <input
                  required
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-2 rounded-lg"
                />
              </div>
            </div>
            <div className=" flex flex-col  ">
              <p className=" text-[20px]">Username:</p>
              <input
                required
                type="text"
                value={name}
                placeholder="User Name"
                onChange={(e) => setName(e.target.value)}
                className=" border-[2px] text-black  outline-none p-2 rounded-lg"
              />
            </div>
            <div className="flex flex-col">
              <label className="pb-2 text-[20px]">Date Of Birth:</label>
              <input
                required
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                className="border-[2px] text-black outline-none p-1 rounded-lg"
              />
            </div>
            <div className=" flex flex-col">
              <p className=" text-[20px]">Email:</p>
              <input
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className=" border-[2px] text-black  outline-none p-2 rounded-lg"
              />
            </div>
            <div className=" relative flex flex-col">
              <p className=" text-[20px]">Password:</p>
              <input
                required
                type="text"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                className=" border-[2px] text-black  outline-none p-2 rounded-lg"
              />
            </div>
            <div className=" flex flex-col  ">
              <p className=" text-[20px]">Confirm Password:</p>
              <input
                required
                type="text"
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className=" border-[2px] text-black  outline-none p-2 rounded-lg"
              />
            </div>

            <div className="grid grid-cols-2 gap-5">
              <div className=" flex flex-col  ">
                <label className="pb-2 text-[20px]">Role:</label>
                <Select
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  className=" "
                >
                  {rolesData &&
                    rolesData.map((role, index) => (
                      <MenuItem id="tone-div" key={index} value={role.role_id}>
                        {role.role_name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              {userData?.roles?.includes("superadmin") &&
                (selectedRole.toString() === "31" ? (
                  <div className=" flex flex-col  ">
                    <label className="pb-2 text-[20px]">No School</label>
                  </div>
                ) : (
                  <div className=" flex flex-col  ">
                    <label className="pb-2 text-[20px]">School:</label>
                    <Select
                      value={selectedSchool}
                      onChange={(e) => setSelectedSchool(e.target.value)}
                      className=" "
                    >
                      {schoolsData &&
                        schoolsData.map((school, index) => (
                          <MenuItem id="tone-div" key={index} value={school.id}>
                            {school.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ))}
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create User
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddUser;
