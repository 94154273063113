import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { MenuItem, Select } from "@mui/material";
import config from "../Config";
import JoditEditor from "jodit-react";

function AddCourse({ setRefresh }: any) {
  const [name, setName] = useState("");
  const [difficulty, setDifficulty] = useState("1");
  const [courseLevel, setCourseLevel] = useState("L1");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState<number>();
  const [file, setFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const editor = useRef(null);

  const handleDifficultyChange = (e: any) => {
    setDifficulty(e.target.value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    if (price && price < 0) {
      setPrice(0);
    }
  }, [price]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select an image!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("difficulty", difficulty);
      formData.append("course_level", courseLevel);
      if (price) {
        formData.append("price", price.toString());
      }
      formData.append("description", description);
      formData.append("logo", file);

      await api.post(`${process.env.REACT_APP_CREATE_COURSE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      window.location.href = "/courses";
      toast.success("Course has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while creating the Course: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while creating the Course", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error creating the Course:", error);
    }
  };

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center w-[210px]  gap-1">
        <Link
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={"/courses"}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Add Course:</p>
      </div>

      <form
        onSubmit={handleUploadSubmit}
        className="relative h-screen"
        style={{ maxHeight: "calc(100vh - 160px)" }}
      >
        <div
          className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
          style={{ maxHeight: "calc(100vh - 210px)" }}
        >
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px]">Course Name:</label>
            <input
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" border-[2px] text-black  outline-none p-1 rounded-lg"
            />
          </div>
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px]">Price:</label>
            <input
              required
              type="number"
              value={price ?? ""}
              onChange={(e) =>
                setPrice(
                  e.target.value ? parseFloat(e.target.value) : undefined
                )
              }
              className="border-[2px] text-black outline-none p-1 rounded-lg"
            />
          </div>
          <div className=" flex flex-col mb-10">
            <label className="pb-2 text-[20px]">Description:</label>
            <JoditEditor
              ref={editor}
              config={config}
              value={description}
              onChange={setDescription}
            />
          </div>
          <div className="flex flex-row mt-3">
            <label
              className="text-[20px] content-center mr-4"
              htmlFor="english"
            >
              Course Level:
            </label>
            <Select
              value={courseLevel}
              onChange={(e) => {
                setCourseLevel(e.target.value);
              }}
            >
              <MenuItem key="beginner" value="L1">
                L1
              </MenuItem>
              <MenuItem key="intermediate" value="L2">
                L2
              </MenuItem>
              <MenuItem key="advanced" value="L3">
                L3
              </MenuItem>
              <MenuItem key="advanced" value="L4">
                L4
              </MenuItem>
              <MenuItem key="advanced" value="L5">
                L5
              </MenuItem>
              <MenuItem key="advanced" value="L6">
                L6
              </MenuItem>
            </Select>
          </div>
          <div className="flex flex-row mt-3">
            <label
              className="text-[20px] content-center mr-4"
              htmlFor="english"
            >
              Difficulty:
            </label>
            <Select value={difficulty} onChange={handleDifficultyChange}>
              <MenuItem key="beginner" value="1">
                Beginner
              </MenuItem>
              <MenuItem key="intermediate" value="2">
                Intermediate
              </MenuItem>
              <MenuItem key="advanced" value="3">
                Advanced
              </MenuItem>
            </Select>
          </div>
          <div className=" flex flex-col ">
            <label className="pb-2 text-[20px]">Image:</label>
            <div className="col-span-4  gap-4 justify-center items-center mb-2 ">
              <img width={200} src={imageSrc} alt="" loading="lazy" />
            </div>
            <input
              required
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className=" flex justify-center absolute w-full bottom-0 ">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Create Course
          </button>
        </div>
      </form>
    </section>
  );
}

export default AddCourse;
