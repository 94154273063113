import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";

const ProtectedRoute = () => {
  // const storedUserId = localStorage.getItem("user_id") || "";
  const accessToken = Cookies.get("access_token");
  const storedUserId = Cookies.get("user_id") || "";

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!accessToken) {
      setLoading(false);
      return;
    }

    const apiUrl = `${process.env.REACT_APP_API}${process.env.REACT_APP_USER_DETAILS}${storedUserId}`;

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data === null) {
          Cookies.remove("access_token");
          localStorage.removeItem("user_name");
          localStorage.removeItem("user_email");
          localStorage.removeItem("user_id");
          localStorage.removeItem("courseId");
          localStorage.removeItem("chapterId");
        } else {
          setUserData(data);
        }
      })
      .catch((error) => {
        Cookies.remove("access_token");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_id");
        localStorage.removeItem("courseId");
        localStorage.removeItem("chapterId");
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, storedUserId]);

  if (loading) {
    return (
      <div className=" flex justify-center items-center animate-spin w-screen h-screen">
        <ImSpinner2 size={25} />
      </div>
    );
  }

  return accessToken ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoute;
