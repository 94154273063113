import { useEffect, useMemo, useState } from "react";
import { ImSpinner, ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import DeleteContract from "../Contracts/DeleteContract";
import AddContract from "../Contracts/AddContract";
import { MenuItem, Select } from "@mui/material";
import countryList from "react-select-country-list";

let currentIdDatabase = 0;

function EditSchool({ setRefreshData }: any) {
  interface SchoolData {
    id: string;
    name: string;
    max_students: number;
    contact_info: string;
    country: string;
    city: string;
    street: string;
  }

  interface ContractsData {
    id: string;
    name: string;
    total_price: string;
    status: string;
  }

  const { school_name } = useParams();
  const { state } = useLocation();

  const [name, setName] = useState("");
  const [maxStudents, setMaxStudents] = useState<number | null>(0);
  const [contactInfo, setContactInfo] = useState("");
  const [country, setCountry] = useState("-1");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const [schoolId, setSchoolId] = useState<string>(() => {
    const storedSchoolId = window.localStorage.getItem("schoolId") || "";
    return (state && state.school_id) || storedSchoolId;
  });

  const [schoolData, setSchoolData] = useState<SchoolData>();

  const [contractsData, setContractsData] = useState<ContractsData[]>([]);

  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);
  const [isAddContractOpen, setIsAddContractOpen] = useState(false);
  const [isDeleteContractOpen, setIsDeleteContractOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openAddContract = () => {
    setIsAddContractOpen(true);
  };

  const handleAddContractClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeAddContract();
    }
  };

  const closeAddContract = () => {
    setIsAddContractOpen(false);
  };

  const openDeleteContract = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteContractOpen(true);
  };

  const closeDeleteContract = () => {
    setIsDeleteContractOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteContract();
    }
  };

  const handleTabClick = async (temp: any) => {
    setTabChange(temp);
  };

  useEffect(() => {
    if (tabChange === "general") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_SCHOOL_BY_ID}${schoolId}`,
        setSchoolData
      );

      setContractsData([]);
    } else if (tabChange === "contracts") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_CONTRACTS}${schoolId}`,
        setContractsData
      );
    }
    setLoading1(false);
  }, [tabChange]);

  useEffect(() => {
    if (tabChange === "general") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_SCHOOL_BY_ID}${schoolId}`,
        setSchoolData
      );

      setContractsData([]);
    } else if (tabChange === "contracts") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_CONTRACTS}${schoolId}`,
        setContractsData
      );
    }
    setLoading1(false);
  }, [refresh, setRefreshData]);

  useEffect(() => {
    if (schoolData) {
      setName(schoolData.name);
      setMaxStudents(schoolData.max_students);
      setContactInfo(schoolData.contact_info);
      setCountry(schoolData.country);
      setCity(schoolData.city);
      setStreet(schoolData.street);
    }
  }, [schoolData]);

  useEffect(() => {
    if (maxStudents && maxStudents < 0) {
      setMaxStudents(0);
    }
  }, [maxStudents]);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (schoolId !== undefined) {
      formData.append("id", schoolId);
    }

    if (tabChange === "general") {
      formData.append("name", name);
      formData.append("contact_info", contactInfo);
      formData.append("city", city);
      formData.append("street", street);

      if (country !== null) {
        if (country === "-1") {
          toast.error("Please select a country!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append("country", country);
        }
      }

      if (maxStudents !== null) {
        if (maxStudents <= 0) {
          toast.error("Maximum number of students must be a positive number!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append("max_students", maxStudents.toString());
        }
      }
    }

    try {
      let endpoint = "";

      if (tabChange === "general") {
        endpoint = `${process.env.REACT_APP_EDIT_SCHOOL_GENERAL}${schoolId}`;
      }

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshData((prevRefresh: any) => prevRefresh + 1);

      // window.location.href = `/schools`;
      toast.success("School has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while editing School", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.error(error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute">
          <Link to={"/schools"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="school" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          Edit {tabChange} Section for {schoolData?.name} school
        </p>
        <div className="flex flex-row justify-between gap-4 w-full">
          <div className="flex flex-col gap-3 max-w-[200px] w-full pt-8">
            <button
              onClick={() => handleTabClick("general")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "general"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300 font-semibold"
                  : " border-[#1b1436]"
              }`}
            >
              <p>School Info</p>
              <IoIosArrowForward />
            </button>
            <button
              onClick={() => handleTabClick("contracts")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "contracts"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300 font-semibold"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Contracts</p>
              <IoIosArrowForward />
            </button>
          </div>

          {loading1 === true ? (
            <div className="w-full flex justify-center items-center pt-16">
              <ImSpinner2 size={25} className="animate-spin" />
            </div>
          ) : (
            <>
              {tabChange === "general" && (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="grid grid-cols-2 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            School Name:
                          </label>
                          <input
                            required
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Number of Students:
                          </label>
                          <input
                            required
                            type="number"
                            value={maxStudents ?? ""}
                            onChange={(e) =>
                              setMaxStudents(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                      </div>
                      <div>
                        <label className="pb-2 text-[22px]">Address:</label>
                        <div className="grid grid-cols-3 gap-5">
                          <div className=" flex flex-col ">
                            <label className="pb-2 text-[20px]">Country:</label>
                            <Select
                              value={country}
                              onChange={(e) => {
                                setCountry(e.target.value);
                              }}
                            >
                              <MenuItem key="-1" value="-1">
                                Choose Country
                              </MenuItem>
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                          <div className=" flex flex-col ">
                            <label className="pb-2 text-[20px]">City:</label>
                            <input
                              required
                              type="text"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              className=" border-[2px] text-black  outline-none p-1 rounded-lg h-full"
                            />
                          </div>

                          <div className=" flex flex-col ">
                            <label className="pb-2 text-[20px]">Street:</label>
                            <input
                              required
                              type="text"
                              value={street}
                              onChange={(e) => setStreet(e.target.value)}
                              className=" border-[2px] text-black  outline-none p-1 rounded-lg h-full"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-3">
                        <div className=" flex flex-col ">
                          <label className="pb-2 text-[20px]">
                            Contact Information:
                          </label>
                          <input
                            required
                            type="text"
                            value={contactInfo}
                            onChange={(e) => setContactInfo(e.target.value)}
                            className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              )}

              {tabChange === "contracts" && (
                <div className="w-full">
                  <div className="flex justify-end mb-3 ">
                    <button
                      onClick={openAddContract}
                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300"
                    >
                      Create Contract
                    </button>
                  </div>
                  {contractsData && contractsData.length > 0 ? (
                    <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                      <table className="w-full text-sm text-center">
                        <thead className="border-b bg-gray-300">
                          <tr>
                            <th scope="col" className="px-6 py-3 w-20">
                              ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Contract
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Total Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Status
                            </th>
                            <th scope="col" className="px-6 py-3 w-[180px]">
                              Contract Management
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contractsData.map((contract, index) => (
                            <tr key={index} className="bg-gray-200 border-b">
                              <td className="px-6 py-4">{contract.id}</td>
                              <td className="px-6 py-4">{contract.name}</td>
                              <td className="px-6 py-4">
                                {contract.total_price}$
                              </td>
                              <td className="px-6 py-4">{contract.status}</td>
                              <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                                <Link
                                  state={{
                                    contract_id: contract.id,
                                    school_id: schoolId,
                                  }}
                                  to={`/schools/edit_school/${schoolData?.name}/edit_contract/${contract.name}`}
                                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                                >
                                  <BiEdit />
                                </Link>
                                <button
                                  onClick={() => openDeleteContract(index)}
                                  className="bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                                >
                                  <MdDelete />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              )}
            </>
          )}
          {isAddContractOpen && (
            <AddContract
              handleAddContractClick={handleAddContractClick}
              closeAddContract={closeAddContract}
              school_id={schoolId}
              setRefresh={setRefresh}
              setRefreshData={setRefreshData}
            />
          )}
          {isDeleteContractOpen && (
            <DeleteContract
              handleDeleteClick={handleDeleteClick}
              closeDeleteContract={closeDeleteContract}
              idEdit={contractsData[currentIdDatabase].id}
              nameEdit={contractsData[currentIdDatabase].name}
              setRefresh={setRefresh}
              setRefreshData={setRefreshData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default EditSchool;
