import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import api from "../../../../api";
import { fetchData } from "../../../../GlobalFunctions";
import { MenuItem, Select } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";

function TeacherGradeStudents({ setRefreshUserData, userData }: any) {
  interface StudentsData {
    user_id: string;
    user_name: string;
  }

  const { grade_name } = useParams();
  const { state } = useLocation();

  const [gradeId, setGradetId] = useState<string>(() => {
    const storedGradeId = window.localStorage.getItem("gradeId") || "";
    return (state && state.grade_id) || storedGradeId;
  });

  const [studentsData, setStudentsData] = useState<StudentsData[]>([]);

  const [filteredStudents, setFilteredStudents] = useState<StudentsData[]>([]);

  const [studentsSearchTerm, setStudentsSearchTerm] = useState("");

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_GRADE_STUDENTS}${userData.school_id}/${gradeId}`,
      setStudentsData
    );
  }, [refresh]);

  useEffect(() => {
    if (studentsData) {
      const filtered = studentsData
        .filter((student) =>
          student.user_name
            .toLowerCase()
            .includes(studentsSearchTerm.toLowerCase())
        )
        .sort((a, b) => a.user_name.localeCompare(b.user_name));
      setFilteredStudents(filtered);
    }
  }, [studentsData, studentsSearchTerm, refresh]);

  return (
    <div>
      <div className="relative pb-4">
        <div className="absolute">
          <Link to={"/teacher-grades"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="grade" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
      </div>
      <div className="pt-8">
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div className=" flex flex-col  ">
            <label className="pb-2 text-[20px]">Students:</label>
            <input
              type="text"
              placeholder="Search by name"
              value={studentsSearchTerm}
              onChange={(e) => setStudentsSearchTerm(e.target.value)}
              className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
            />
          </div>
        </div>
      </div>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg  mb-24">
        <table className="w-full text-sm text-left ">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredStudents?.map((student, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{student.user_id}</td>
                <td className="px-6 py-4">{student.user_name}</td>

                <td className="px-6 py-4 flex flex-row gap-2">
                  <Link
                    state={{ student_id: student.user_id, grade_id: gradeId }}
                    to={`/teacher-grades/view-grade/${grade_name}/student-comment/${student.user_name}`}
                    className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    Edit Comment
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TeacherGradeStudents;
