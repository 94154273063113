import { useEffect, useState } from "react";
import EditUser from "./EditUser";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import api from "../../../../api";
import DeleteUser from "./DeleteUser";
import { fetchData } from "../../../../GlobalFunctions";
import AddUser from "./AddUser";
import { MenuItem, Select } from "@mui/material";

let currentIdDatabase = 0;

function Users({ setRefreshUserData, userData }: any) {
  interface UsersData {
    id: string;
    username: string;
    user_email: string;
    is_enabled: number;
    school_id: string;
    school_name: string;
    role: string;
    role_id: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
  }

  interface SchoolsData {
    name: string;
    id: string;
  }

  interface RolesData {
    role_name: string;
    role_id: string;
  }

  const [isAddRoleOpen, setIsAddRoleOpen] = useState(false);

  const [usersData, setUsersData] = useState<UsersData[] | null>(null);

  const [filteredUsers, setFilteredUsers] = useState<UsersData[] | null>(null);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);
  const [selectedSchool, setSelectedSchool] = useState("all");
  const [rolesData, setRolesData] = useState<RolesData[]>([]);
  const [selectedRole, setSelectedRole] = useState("all");

  const [userEditOpen, setUserEditOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (userData.roles.includes("superadmin")) {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
        setSchoolsData
      );
    }
    fetchData(`${process.env.REACT_APP_ROLES_DETAILS}`, setRolesData);
  }, [refresh, setRefreshUserData]);

  const handleAddUserClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeAddUser();
    }
  };

  const openAddUser = () => {
    setIsAddRoleOpen(true);
  };

  const closeAddUser = () => {
    setIsAddRoleOpen(false);
  };

  const openEditUser = async (id: number) => {
    currentIdDatabase = id;
    setUserEditOpen(true);
  };

  const closeEditUser = () => {
    setUserEditOpen(false);
  };

  const handleUserEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeEditUser();
    }
  };

  useEffect(() => {
    setIsDeleteRoleOpen(false);
    fetchData(`${process.env.REACT_APP_ALL_USERS}`, setUsersData);
  }, [refresh]);

  const [isDeleteRoleOpen, setIsDeleteRoleOpen] = useState(false);
  const openDeleteRole = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteRoleOpen(true);
  };

  const closeDeleteRole = () => {
    setIsDeleteRoleOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteRole();
    }
  };

  useEffect(() => {
    if (usersData) {
      if (selectedSchool === "all" && selectedRole === "all") {
        const filtered = usersData
          .filter((user) =>
            user.username.toLowerCase().includes(usersSearchTerm.toLowerCase())
          )
          .sort((a, b) => a.username.localeCompare(b.username));
        setFilteredUsers(filtered);
      } else if (selectedSchool === "all") {
        const filtered = usersData
          .filter(
            (user) =>
              user.username
                .toLowerCase()
                .includes(usersSearchTerm.toLowerCase()) &&
              (selectedRole ? user.role === selectedRole : true)
          )
          .sort((a, b) => a.username.localeCompare(b.username));

        setFilteredUsers(filtered);
      } else if (selectedRole === "all") {
        const filtered = usersData
          .filter(
            (user) =>
              user.username
                .toLowerCase()
                .includes(usersSearchTerm.toLowerCase()) &&
              (selectedSchool ? user.school_name === selectedSchool : true)
          )
          .sort((a, b) => a.username.localeCompare(b.username));

        setFilteredUsers(filtered);
      } else {
        const filtered = usersData
          .filter(
            (user) =>
              user.username
                .toLowerCase()
                .includes(usersSearchTerm.toLowerCase()) &&
              (selectedRole ? user.role === selectedRole : true) &&
              (selectedSchool ? user.school_name === selectedSchool : true)
          )
          .sort((a, b) => a.username.localeCompare(b.username));

        setFilteredUsers(filtered);
      }
    }
  }, [usersData, usersSearchTerm, selectedRole, selectedSchool]);

  return (
    <div>
      <div className="flex justify-end mb-3">
        <button
          onClick={openAddUser}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Create User
        </button>
      </div>
      <div>
        <div className="grid grid-cols-3 gap-5 mb-5">
          <div className=" flex flex-col  ">
            <label className="pb-2 text-[20px]">Name:</label>
            <input
              type="text"
              placeholder="Search by name"
              value={usersSearchTerm}
              onChange={(e) => setUsersSearchTerm(e.target.value)}
              className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
            />
          </div>

          <div className=" flex flex-col  ">
            <label className="pb-2 text-[20px]">Role:</label>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              className=" "
            >
              <MenuItem id="tone-div" key="all" value="all">
                All
              </MenuItem>
              {rolesData &&
                rolesData.map((role, index) => (
                  <MenuItem id="tone-div" key={index} value={role.role_name}>
                    {role.role_name}
                  </MenuItem>
                ))}
            </Select>
          </div>

          {userData?.roles?.includes("superadmin") && (
            <div className=" flex flex-col  ">
              <label className="pb-2 text-[20px]">School:</label>
              <Select
                value={selectedSchool}
                onChange={(e) => setSelectedSchool(e.target.value)}
                className=" "
              >
                <MenuItem id="tone-div" key="all" value="all">
                  All
                </MenuItem>
                {schoolsData &&
                  schoolsData.map((school, index) => (
                    <MenuItem id="tone-div" key={index} value={school.name}>
                      {school.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}
        </div>
      </div>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg  mb-24">
        <table className="w-full text-sm text-left ">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                School
              </th>
              <th scope="col" className="px-6 py-3">
                Active
              </th>

              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers?.map((user, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{user.id}</td>
                <td className="px-6 py-4">{user.username}</td>
                <td className="px-6 py-4">{user.user_email}</td>
                <td className="px-6 py-4">{user.role}</td>
                <td className="px-6 py-4">{user.school_name}</td>
                <td className="px-6 py-4">
                  {user.is_enabled === 0 ? "False" : "True"}
                </td>

                <td className="px-6 py-4 flex flex-row gap-2">
                  {userData?.roles?.includes("superadmin") ? (
                    <button
                      onClick={() => openEditUser(index)}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <BiEdit />
                    </button>
                  ) : (
                    user.role !== "schooladmin" && (
                      <button
                        onClick={() => openEditUser(index)}
                        className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                      >
                        <BiEdit />
                      </button>
                    )
                  )}

                  <button
                    onClick={() => openDeleteRole(index)}
                    className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    <MdDelete />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {filteredUsers
        ? userEditOpen && (
            <EditUser
              handleUserEditClick={handleUserEditClick}
              closeEditUser={closeEditUser}
              nameEdit={filteredUsers[currentIdDatabase].username}
              roleEdit={filteredUsers[currentIdDatabase].role_id}
              emailEdit={filteredUsers[currentIdDatabase].user_email}
              fristNameEdit={filteredUsers[currentIdDatabase].first_name}
              lastNameEdit={filteredUsers[currentIdDatabase].last_name}
              dateOfBirthEdit={filteredUsers[currentIdDatabase].date_of_birth}
              schoolIdEdit={filteredUsers[currentIdDatabase].school_id}
              idEdit={filteredUsers[currentIdDatabase].id}
              enabledEdit={filteredUsers[currentIdDatabase].is_enabled}
              setRefresh={setRefresh}
              setRefreshUserData={setRefreshUserData}
              userData={userData}
            />
          )
        : null}
      {isAddRoleOpen && (
        <AddUser
          userData={userData}
          handleUserClick={handleAddUserClick}
          closeUser={closeAddUser}
          setRefresh={setRefresh}
          refresh={refresh}
          setRefreshUserData={setRefreshUserData}
        />
      )}
      {filteredUsers && isDeleteRoleOpen && (
        <DeleteUser
          handleDeleteClick={handleDeleteClick}
          closeDeleteRole={closeDeleteRole}
          idEdit={filteredUsers[currentIdDatabase].id}
          nameEdit={filteredUsers[currentIdDatabase].username}
          schoolId={filteredUsers[currentIdDatabase].school_id}
          setRefresh={setRefresh}
        />
      )}
    </div>
  );
}

export default Users;
