import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import { MenuItem, Select } from "@mui/material";
import { fetchData } from "../../../../GlobalFunctions";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

function EditUser({
  nameEdit,
  emailEdit,
  schoolIdEdit,
  idEdit,
  handleUserEditClick,
  closeEditUser,
  enabledEdit,
  setRefresh,
  setRefreshUserData,
  userData,
  fristNameEdit,
  lastNameEdit,
  dateOfBirthEdit,
  roleEdit,
}: any) {
  interface RolesData {
    role_name: string;
    role_id: string;
  }

  interface SchoolsData {
    name: string;
    id: string;
  }

  const [userID, setUserID] = useState<any | null>(
    localStorage.getItem("user_id")
  );

  const [name, setName] = useState(nameEdit);
  const [firstName, setFirstName] = useState(fristNameEdit);
  const [lastName, setLastName] = useState(lastNameEdit);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    return `${year}-${month}-${day}`;
  };

  const [dateOfBirth, setDateOfBirth] = useState<string>("");

  useEffect(() => {
    if (dateOfBirthEdit) {
      setDateOfBirth(formatDate(dateOfBirthEdit));
    }
  }, [dateOfBirthEdit]);
  const [email, setEmail] = useState(emailEdit);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [rolesData, setRolesData] = useState<RolesData[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [isEnabled, setIsEnabled] = useState<string>(
    enabledEdit === 0 ? "false" : "true"
  );

  const [selectedSchool, setSelectedSchool] = useState("");
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);

  useEffect(() => {
    if (userData.roles.includes("superadmin")) {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
        setSchoolsData
      );
    }

    fetchData(`${process.env.REACT_APP_ROLES_DETAILS}`, setRolesData);
  }, [userID]);

  useEffect(() => {
    if (
      rolesData.length > 0 &&
      rolesData.some((role) => role.role_id === roleEdit)
    ) {
      setSelectedRole(roleEdit);
    } else if (rolesData.length > 0) {
      setSelectedRole(rolesData[0].role_id); // Set to the first role by default
    }
  }, [rolesData, roleEdit]);

  useEffect(() => {
    if (userData?.roles?.includes("superadmin")) {
      if (
        schoolsData.length > 0 &&
        !schoolsData.some((school) => school.id === selectedSchool)
      ) {
        if (schoolIdEdit) {
          setSelectedSchool(schoolIdEdit);
        } else {
          setSelectedSchool("");
        }
      }
    }
  }, [schoolsData, selectedSchool]);

  // const handleCheckBoxChange = (role_id: string) => {
  //   setCheckedRoles((prevRoles) => {
  //     if (prevRoles.includes(role_id)) {
  //       return prevRoles.filter((roleId) => roleId !== role_id);
  //     } else {
  //       return [...prevRoles, role_id];
  //     }
  //   });
  // };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (password !== "") {
      if (password !== confirmPassword) {
        toast.error("Passwords do not match!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      }

      const passwordPattern =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.{8,})/;

      if (!passwordPattern.test(password)) {
        toast.error(
          "Password must contain at least 1 capital letter, 1 small letter, 1 special character, 1 number, and be at least 8 characters long.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
        return;
      }

      formData.append("password", password);
    }

    formData.append("user_id", idEdit);
    formData.append("user_name", name);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    if (dateOfBirth !== undefined) {
      formData.append("date_of_birth", dateOfBirth);
    }
    formData.append("user_email", email);
    if (userData.roles.includes("superadmin")) {
      formData.append("school_id", selectedSchool);
    }
    formData.append("role_ids", selectedRole);

    formData.append("is_enabled", isEnabled);

    try {
      await api.post(`${process.env.REACT_APP_EDIT_USER}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);

      closeEditUser();
      toast.success("User information has been updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while updating the user information", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default "
      onMouseDown={handleUserEditClick}
      role="button"
    >
      <div className="relative bg-white p-4 rounded shadow-2xl h-[90%] overflow-scroll">
        <button
          onClick={closeEditUser}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] p-1 duration-300 rounded-lg text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>
        <p className="text-[25px] text-center content-center">Edit User</p>
        <form onSubmit={handleEditSubmit} className=" md:w-[750px] w-[350px]">
          <div className="p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-2">
              <div className=" flex flex-col  ">
                <p className=" text-[20px]">First Name:</p>
                <input
                  required
                  type="text"
                  value={firstName}
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-2 rounded-lg"
                />
              </div>
              <div className=" flex flex-col  ">
                <p className=" text-[20px]">Last Name:</p>
                <input
                  required
                  type="text"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-2 rounded-lg"
                />
              </div>
            </div>
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Username:</label>
              <input
                required
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
              />
            </div>
            <div className="flex flex-col">
              <label className="pb-2 text-[20px]">Date Of Birth:</label>
              <input
                required
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                className="border-[2px] text-black outline-none p-1 rounded-lg"
              />
            </div>
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Email:</label>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
              />
            </div>
            <div className=" flex flex-col ">
              <label className="pb-2 text-[20px]">Set New Password:</label>
              <div className="relative w-full">
                <input
                  type={isVisible ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  className=" border-[2px] w-full text-black  outline-none p-1 rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => setIsVisible(!isVisible)}
                  className="outline-none text-[#135991] absolute right-2 top-2"
                >
                  {isVisible ? <BiSolidShow /> : <BiSolidHide />}
                </button>
              </div>
            </div>
            <div className=" flex flex-col">
              <label className="pb-2 text-[20px]">Confirm Password:</label>
              <input
                type={isVisible ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm Password"
                className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-2 gap-5">
              <div className=" flex flex-col  ">
                <label className="pb-2 text-[20px]">Role:</label>
                <Select
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  className=" "
                >
                  {rolesData &&
                    rolesData.map((role, index) => (
                      <MenuItem id="tone-div" key={index} value={role.role_id}>
                        {role.role_name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              {userData?.roles?.includes("superadmin") &&
                (selectedRole.toString() === "31" ? (
                  <div className="flex flex-col">
                    <label className="pb-2 text-[20px]">No School</label>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <label className="pb-2 text-[20px]">School:</label>
                    <Select
                      value={selectedSchool}
                      onChange={(e) => setSelectedSchool(e.target.value)}
                      className=""
                    >
                      {schoolsData &&
                        schoolsData.map((school, index) => (
                          <MenuItem key={index} value={school.id}>
                            {school.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ))}
            </div>
            <div className="mt-4 flex flex-col">
              <label className="pb-2 text-[20px]">Status:</label>
              <div className="flex items-center gap-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="true"
                    checked={isEnabled === "true"}
                    onChange={() => setIsEnabled("true")}
                    className="form-radio h-5 w-5 text-blue-600"
                  />
                  <span className="ml-2 text-sm">Enabled</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="false"
                    checked={isEnabled === "false"}
                    onChange={() => setIsEnabled("false")}
                    className="form-radio h-5 w-5 text-red-600"
                  />
                  <span className="ml-2 text-sm">Disabled</span>
                </label>
              </div>
            </div>

            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default EditUser;
