import { useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import JoditEditor from "jodit-react";
import config from "../Config";

function AddContract({
  handleAddContractClick,
  closeAddContract,
  school_id,
  setRefresh,
  setRefreshData,
}: any) {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [editDeadline, setEditDeadline] = useState<string | undefined>(
    undefined
  );
  const [terms, setTerms] = useState("");
  const editor = useRef(null);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      toast.error("Start date cannot be after end date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    if (startDate && new Date(newEndDate) < new Date(startDate)) {
      toast.error("End date cannot be before start date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setEndDate(newEndDate);
    }
  };

  const handleEditDeadlineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEditDeadline = e.target.value;
    if (editDeadline && new Date(newEditDeadline) > new Date(editDeadline)) {
      toast.error("Edit Deadline date cannot be after end date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setEditDeadline(newEditDeadline);
    }
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("terms", terms);
    if (startDate !== undefined) {
      formData.append("start_date", startDate);
    }
    if (endDate !== undefined) {
      formData.append("end_date", endDate);
    }

    if (editDeadline !== undefined) {
      formData.append("edit_deadline", editDeadline);
    }

    try {
      await api.post(
        `${process.env.REACT_APP_CREATE_CONTRACT}${school_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshData((prevRefresh: any) => prevRefresh + 1);
      closeAddContract();
      toast.success("Contract has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while creating the Contract", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
      onMouseDown={handleAddContractClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeAddContract}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">
          Create Contract
        </p>

        <form
          onSubmit={handleUploadSubmit}
          className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll"
        >
          <div className="p-4 flex flex-col gap-4">
            <div className="grid grid-cols-1 gap-2">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Contract Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Terms:</label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={terms}
                  onChange={setTerms}
                />
              </div>
              <div className="grid grid-cols-2 gap-2 mt-12">
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]">Start Date:</label>
                  <input
                    required
                    type="date"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]">End Date:</label>
                  <input
                    required
                    type="date"
                    value={endDate}
                    onChange={handleEndDateChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 mt-12">
                <div className="flex flex-col">
                  <label className="pb-2 text-[20px]">Edit Deadline:</label>
                  <input
                    required
                    type="date"
                    value={editDeadline}
                    onChange={handleEditDeadlineChange}
                    className="border-[2px] text-black outline-none p-1 rounded-lg"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Contract
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddContract;
