import { useMemo, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";
import countryList from "react-select-country-list";
import { MenuItem, Select } from "@mui/material";

function AddSchool({
  handleSchoolClick,
  closeSchool,
  setRefresh,
  setRefreshData,
}: any) {
  const [name, setName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [maxStudents, setMaxStudents] = useState<number | null>(0);
  const [country, setCountry] = useState("-1");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact_info", contactInfo);
    formData.append("city", city);
    formData.append("street", street);

    if (country !== null) {
      if (country === "-1") {
        toast.error("Please select a country!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("country", country);
      }
    }

    if (maxStudents !== null) {
      if (maxStudents <= 0) {
        toast.error("Maximum number of students must be a positive number!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("max_students", maxStudents.toString());
      }
    }

    try {
      await api.post(`${process.env.REACT_APP_CREATE_SCHOOL}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshData((prevRefresh: any) => prevRefresh + 1);
      closeSchool();
      toast.success("School has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while creating the School", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
      onMouseDown={handleSchoolClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeSchool}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create School</p>

        <form
          onSubmit={handleUploadSubmit}
          className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll"
        >
          <div className="p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-5">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">School Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Number of Students:</label>
                <input
                  required
                  type="number"
                  value={maxStudents ?? ""}
                  onChange={(e) =>
                    setMaxStudents(
                      e.target.value ? parseFloat(e.target.value) : null
                    )
                  }
                  className="border-[2px] text-black outline-none p-1 rounded-lg "
                />
              </div>
            </div>
            <div>
              <label className="pb-2 text-[22px]">Address:</label>
              <div className="grid grid-cols-3 gap-5">
                <div className=" flex flex-col mt-2">
                  <label className="pb-2 text-[18px]">Country:</label>
                  <Select
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  >
                    <MenuItem key="-1" value="-1">
                      Choose Country
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className=" flex flex-col ">
                  <label className="pb-2 text-[18px]">City:</label>
                  <input
                    required
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className=" border-[2px] text-black  outline-none p-1 rounded-lg h-full"
                  />
                </div>

                <div className=" flex flex-col ">
                  <label className="pb-2 text-[18px]">Street:</label>
                  <input
                    required
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    className=" border-[2px] text-black  outline-none p-1 rounded-lg h-full"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Contact Information:</label>
                <input
                  required
                  type="text"
                  value={contactInfo}
                  onChange={(e) => setContactInfo(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
            </div>

            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create School
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddSchool;
