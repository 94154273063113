import boxMind from "../../../../Assets/boxMind_transparent.png";

const HomeScreen = () => {
  return (
    <section className="flex flex-col h-full justify-center items-center pb-12">
      <img
        src={boxMind}
        alt="boxMind"
        width={220}
        height={130}
        loading="lazy"
      />
      <p className="text-center text-[#1D6196] text-[30px] font-normal capitalize leading-snug">
        Think inside the box with AI
      </p>
    </section>
  );
};

export default HomeScreen;
