import { useState, useEffect } from "react";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import { toast } from "react-toastify";
import IMG from "../../../../../Assets/dashboard-img.webp";
import GaugeChart from "react-gauge-chart";

function StudentQuiz({ setRefresh }: any) {
  interface Quiz {
    quiz_id: string;
    course_name: string;
    chapter_name: string;
    correct_answers: string;
    total_questions: string;
    score: string;
    grade_name: string;
  }

  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [quizzesData, setQuizzesData] = useState<Quiz[]>([]);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_QUIZ_BY_USER_ID}`,
      setQuizzesData
    );
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-4">
        <table className="w-full text-sm text-left">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Course
              </th>
              <th scope="col" className="px-6 py-3">
                Chapter
              </th>
              <th scope="col" className="px-6 py-3">
                Corrct Answers
              </th>
              <th scope="col" className="px-6 py-3">
                Grade
              </th>
            </tr>
          </thead>
          <tbody>
            {quizzesData?.map((quiz, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{quiz.quiz_id}</td>
                <td className="px-6 py-4">
                  {quiz.course_name.toLowerCase().includes("ai")
                    ? `AI & Robotics ${quiz.grade_name
                        .toLowerCase()
                        .replace("grade", "")} - Part I`
                    : `AI & Robotics ${quiz.grade_name
                        .toLowerCase()
                        .replace("grade", "")} - Part II`}
                </td>
                <td className="px-6 py-4">{quiz.chapter_name}</td>
                <td className="px-6 py-4">
                  {quiz.correct_answers} / {quiz.total_questions}
                </td>
                <td className="px-6 py-4">{quiz.score} / 100</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default StudentQuiz;
