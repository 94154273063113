import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import AddRole from "./AddRole";
import DeleteRole from "./DeleteRole";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";

let currentIdDatabase = 0;

function Roles({ setRefreshUserData, refreshUserData }: any) {
  interface RoleData {
    role_id: string;
    role_name: string;
  }

  const [roleData, setRoleData] = useState<RoleData[] | null>(null);
  const [isDeleteRoleOpen, setIsDeleteRoleOpen] = useState(false);
  const [isRoleOpen, setIsRoleOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeleteRole = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteRoleOpen(true);
  };

  const closeDeleteRole = () => {
    setIsDeleteRoleOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteRole();
    }
  };

  useEffect(() => {
    setIsDeleteRoleOpen(false);
    fetchData(`${process.env.REACT_APP_ROLES_DETAILS}`, setRoleData);
  }, [refresh, refreshUserData]);

  const openRole = () => {
    setIsRoleOpen(true);
  };

  const handleRoleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeRole();
    }
  };

  const closeRole = () => {
    setIsRoleOpen(false);
  };

  return (
    <div>
      {/* <div className="flex justify-end mb-3">
        <button
          onClick={openRole}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Create Role
        </button>
      </div> */}

      <div className=" overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left">
          <thead className="border-b bg-gray-300">
            <tr>
              <th scope="col" className="px-6 py-3 w-20">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>

              {/* <th scope="col" className="px-6 py-3 w-[180px]">
                role management
              </th> */}
            </tr>
          </thead>
          <tbody>
            {roleData?.map((data, index) => (
              <tr key={index} className="bg-gray-200 border-b ">
                <td className="px-6 py-4">{data.role_id}</td>
                <td className="px-6 py-4">{data.role_name}</td>

                {/* <td className="px-6 py-4 flex flex-row gap-2 w-40">
                  <Link
                    to={`/roles/edit_role/${data.role_id}`}
                    className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    <BiEdit />
                  </Link>

                  <button
                    onClick={() => openDeleteRole(index)}
                    className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                  >
                    <MdDelete />
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        {/* {isRoleOpen && (
          <AddRole
            handleRoleClick={handleRoleClick}
            closeRole={closeRole}
            setRefresh={setRefresh}
            setRefreshUserData={setRefreshUserData}
          />
        )}
        {roleData && isDeleteRoleOpen && (
          <DeleteRole
            handleDeleteClick={handleDeleteClick}
            closeDeleteRole={closeDeleteRole}
            idEdit={roleData[currentIdDatabase].role_id}
            nameEdit={roleData[currentIdDatabase].role_name}
            setRefresh={setRefresh}
            setRefreshUserData={setRefreshUserData}
          />
        )} */}
      </div>
    </div>
  );
}

export default Roles;
