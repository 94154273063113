import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import AddPlan from "./AddPlan";
import DeletePlan from "./DeletePlan";

let currentIdDatabase = 0;

function Plans({ setRefreshData }: any) {
  interface PlansData {
    id: string;
    name: string;
    max_input_tokens_per_day: number;
    max_output_tokens_per_day: number;
    price: number;
  }

  const [plansData, setPlansData] = useState<PlansData[] | null>(null);
  const [isDeletePlanOpen, setIsDeletePlanOpen] = useState(false);
  const [isPlanOpen, setIsPlanOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeletePlan = async (id: number) => {
    currentIdDatabase = id;
    setIsDeletePlanOpen(true);
  };

  const closeDeletePlan = () => {
    setIsDeletePlanOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeletePlan();
    }
  };

  useEffect(() => {
    setIsDeletePlanOpen(false);
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_PLAN}`,
      setPlansData
    );
  }, [refresh, setRefreshData]);

  const openPlan = () => {
    setIsPlanOpen(true);
  };

  const handlePlanClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closePlan();
    }
  };

  const closePlan = () => {
    setIsPlanOpen(false);
  };

  return (
    <div>
      <div className="flex justify-end mb-3">
        <button
          onClick={openPlan}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Create Plan
        </button>
      </div>
      {plansData && plansData.length > 0 ? (
        <div className=" overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-center">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Maximum Input Tokens
                </th>
                <th scope="col" className="px-6 py-3">
                  Maximum Output Tokens
                </th>
                <th scope="col" className="px-6 py-3 w-[180px]">
                  Plan management
                </th>
              </tr>
            </thead>
            <tbody>
              {plansData?.map((plan, index) => (
                <tr key={index} className="bg-gray-200 border-b ">
                  <td className="px-6 py-4">{plan.id}</td>
                  <td className="px-6 py-4">{plan.name}</td>
                  <td className="px-6 py-4">{plan.price}</td>
                  <td className="px-6 py-4">{plan.max_input_tokens_per_day}</td>
                  <td className="px-6 py-4">
                    {plan.max_output_tokens_per_day}
                  </td>

                  <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                    <Link
                      state={{ plan_id: plan.id }}
                      to={`/plans/edit_plan/${plan.name}`}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <BiEdit />
                    </Link>

                    <button
                      onClick={() => openDeletePlan(index)}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      )}
      {isPlanOpen && (
        <AddPlan
          handlePlanClick={handlePlanClick}
          closePlan={closePlan}
          setRefresh={setRefresh}
          setRefreshData={setRefreshData}
        />
      )}
      {plansData && isDeletePlanOpen && (
        <DeletePlan
          handleDeleteClick={handleDeleteClick}
          closeDeletePlan={closeDeletePlan}
          idEdit={plansData[currentIdDatabase].id}
          nameEdit={plansData[currentIdDatabase].name}
          setRefresh={setRefresh}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
}

export default Plans;
