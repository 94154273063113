import { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import api from "../../../../api";

function AddLevel({
  handleLevelClick,
  closeLevel,
  setRefresh,
  setRefreshData,
}: any) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState<number | null>(0);

  useEffect(() => {
    if (price && price < 0) {
      setPrice(0);
    }
  }, [price]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    if (price !== null) {
      if (price <= 0) {
        toast.error("Price must be a positive number!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("price", price.toString());
      }
    }

    try {
      await api.post(`${process.env.REACT_APP_CREATE_LEVEL}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshData((prevRefresh: any) => prevRefresh + 1);
      closeLevel();
      toast.success("Grade has been created successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while creating the Grade", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
      onMouseDown={handleLevelClick}
      role="button"
    >
      <div className="bg-white p-4 rounded shadow-2xl relative">
        <button
          onClick={closeLevel}
          className="text-[25px] bg-[#4D30B5] hover:bg-[#1b1436] duration-300 rounded-lg p-1 text-white absolute top-2 right-2"
        >
          <RxCross2 size={25} className="-mt-[1px]" />
        </button>

        <p className="text-[25px] text-center content-center">Create Grade</p>

        <form
          onSubmit={handleUploadSubmit}
          className=" md:w-[750px] w-[350px] max-h-[600px] overflow-y-scroll"
        >
          <div className="p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-5">
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Grade Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className="flex flex-col">
                <label className="pb-2 text-[20px]">Price:</label>
                <input
                  required
                  type="number"
                  value={price ?? ""}
                  onChange={(e) =>
                    setPrice(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  className="border-[2px] text-black outline-none p-1 rounded-lg "
                />
              </div>
            </div>

            <div className="mt-4 flex justify-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
              >
                Create Grade
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddLevel;
