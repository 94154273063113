import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import AddSchool from "./AddSchool";
import DeleteSchool from "./DeleteSchool";

let currentIdDatabase = 0;

function Schools({ setRefreshData }: any) {
  interface SchoolData {
    id: string;
    name: string;
    max_students: string;
    current_users: string;
  }

  const [schoolsData, setSchoolsData] = useState<SchoolData[] | null>(null);
  const [isDeleteSchoolOpen, setIsDeleteSchoolOpen] = useState(false);
  const [isSchoolOpen, setIsSchoolOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const openDeleteSchool = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteSchoolOpen(true);
  };

  const closeDeleteSchool = () => {
    setIsDeleteSchoolOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteSchool();
    }
  };

  useEffect(() => {
    setIsDeleteSchoolOpen(false);
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
      setSchoolsData
    );
  }, [refresh, setRefreshData]);

  const openSchool = () => {
    setIsSchoolOpen(true);
  };

  const handleSchoolClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeSchool();
    }
  };

  const closeSchool = () => {
    setIsSchoolOpen(false);
  };

  return (
    <div>
      <div className="flex justify-end mb-3">
        <button
          onClick={openSchool}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Create School
        </button>
      </div>
      {schoolsData && schoolsData.length > 0 ? (
        <div className=" overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-center">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  School
                </th>
                <th scope="col" className="px-6 py-3">
                  Number of Students
                </th>
                <th scope="col" className="px-6 py-3">
                  Current Users
                </th>
                <th scope="col" className="px-6 py-3 w-[180px]">
                  School management
                </th>
              </tr>
            </thead>
            <tbody>
              {schoolsData?.map((school, index) => (
                <tr key={index} className="bg-gray-200 border-b ">
                  <td className="px-6 py-4">{school.id}</td>
                  <td className="px-6 py-4">{school.name}</td>
                  <td className="px-6 py-4">{school.max_students}</td>
                  <td className="px-6 py-4">{school.current_users}</td>
                  <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                    <Link
                      state={{ school_id: school.id }}
                      to={`/schools/edit_school/${school.name}`}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <BiEdit />
                    </Link>

                    <button
                      onClick={() => openDeleteSchool(index)}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      )}
      {isSchoolOpen && (
        <AddSchool
          handleSchoolClick={handleSchoolClick}
          closeSchool={closeSchool}
          setRefresh={setRefresh}
          setRefreshData={setRefreshData}
        />
      )}
      {schoolsData && isDeleteSchoolOpen && (
        <DeleteSchool
          handleDeleteClick={handleDeleteClick}
          closeDeleteSchool={closeDeleteSchool}
          idEdit={schoolsData[currentIdDatabase].id}
          nameEdit={schoolsData[currentIdDatabase].name}
          setRefresh={setRefresh}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
}

export default Schools;
