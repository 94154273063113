import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import { MenuItem, Select } from "@mui/material";
import Cookies from "js-cookie";

function SASubscriptions({ setRefreshData, userData }: any) {
  interface SubscriptionsData {
    plan_id: number;
    plan_name: string;
    active_subscriptions: number;
    school_id: number;
    school_name: string;
    school_subscription_id: number;
    subscriptions_purchased: number;
    remaining_subscriptions: number;
    end_date: string;
    total_plan_price: number;
  }
  interface SchoolsData {
    name: string;
    id: string;
  }

  const user_id = Cookies.get("user_id") || "default_user";

  const [subscriptionsData, setSubscriptionsData] = useState<
    SubscriptionsData[]
  >([]);
  const [schoolsData, setSchoolsData] = useState<SchoolsData[]>([]);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<
    SubscriptionsData[] | null
  >(null);
  const [selectedSchool, setSelectedSchool] = useState("all");

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SUBSCRIPTIONS_BY_SA_ID}${user_id}`,
      setSubscriptionsData
    );
    if (userData.roles.includes("superadmin")) {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOLS}`,
        setSchoolsData
      );
    }
  }, [refresh, setRefreshData]);

  useEffect(() => {
    if (subscriptionsData) {
      if (selectedSchool === "all") {
        setFilteredSubscriptions(subscriptionsData);
      } else {
        const filtered = subscriptionsData.filter((subscription) =>
          selectedSchool ? subscription.school_name === selectedSchool : true
        );
        setFilteredSubscriptions(filtered);
      }
    }
  }, [subscriptionsData, selectedSchool]);

  return (
    <div className="w-full">
      {subscriptionsData && subscriptionsData.length > 0 ? (
        <div className="overflow-x-auto shadow-md sm:rounded-lg w-full mb-24">
          {userData?.roles?.includes("superadmin") && (
            <div className="grid grid-cols-3 gap-5 mb-5">
              <div className=" flex flex-col  ">
                <label className="pb-2 text-[20px]">School:</label>
                <Select
                  value={selectedSchool}
                  onChange={(e) => setSelectedSchool(e.target.value)}
                  className=" "
                >
                  <MenuItem id="tone-div" key="all" value="all">
                    All
                  </MenuItem>
                  {schoolsData &&
                    schoolsData.map((school, index) => (
                      <MenuItem id="tone-div" key={index} value={school.name}>
                        {school.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
          )}
          <table className="w-full text-sm text-center">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Subscription
                </th>
                {userData?.roles?.includes("superadmin") && (
                  <th scope="col" className="px-6 py-3">
                    School
                  </th>
                )}
                <th scope="col" className="px-6 py-3">
                  Subscriptions Purchased
                </th>
                <th scope="col" className="px-6 py-3">
                  Active Subscriptions
                </th>
                <th scope="col" className="px-6 py-3">
                  Remaining Subscriptions
                </th>
                <th scope="col" className="px-6 py-3">
                  End Date
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Price Per Student
                </th> */}
                <th scope="col" className="px-6 py-3 w-[180px]">
                  Subscription Management
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredSubscriptions?.map((subscription, index) => (
                <tr key={index} className="bg-gray-200 border-b">
                  <td className="px-6 py-4">
                    {subscription.school_subscription_id}
                  </td>
                  <td className="px-6 py-4">{subscription.plan_name}</td>
                  {userData?.roles?.includes("superadmin") && (
                    <td className="px-6 py-4">{subscription.school_name}</td>
                  )}
                  <td className="px-6 py-4">
                    {subscription.subscriptions_purchased}
                  </td>
                  <td className="px-6 py-4">
                    {subscription.active_subscriptions}
                  </td>
                  <td className="px-6 py-4">
                    {subscription.remaining_subscriptions}
                  </td>
                  <td className="px-6 py-4">{subscription.end_date}</td>
                  {/* <td className="px-6 py-4">
                    {subscription.total_plan_price}$
                  </td> */}

                  <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                    <Link
                      state={{
                        subscription_id: subscription.plan_id,
                        contract_subscription_id:
                          subscription.school_subscription_id,
                        school_id: subscription.school_id,
                      }}
                      to={`/school_subscriptions/edit_subscription/${subscription.plan_name}`}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <BiEdit />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}

export default SASubscriptions;
