import { useEffect, useState } from "react";
import { ImSpinner, ImSpinner2 } from "react-icons/im";

function NotFoundPage() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  if (loading) {
    return (
      <div className=" flex justify-center items-center w-screen h-screen">
        <ImSpinner2 size={25} className="animate-spin" />
      </div>
    );
  }

  return (
    <section className=" w-screen h-screen flex justify-center items-center bg-[#083a5e] text-white">
      <div className=" flex flex-col items-center gap-4">
        <div className=" text-[30px]">Page not found!</div>
        <a
          href="/"
          className=" px-4 py-2 bg-[#ac4e01] hover:bg-[#50341d] duration-300 rounded-md"
        >
          Go back!
        </a>
      </div>
    </section>
  );
}

export default NotFoundPage;
