import { useEffect, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";

function EditRole({ setRefreshUserData }: any) {
  interface RoleData {
    role_name: string;
  }

  const { role_id } = useParams();

  const [roleData, setRoleData] = useState<RoleData>();
  const [name, setName] = useState("");

  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const handleTabClick = async (temp: any) => {
    setLoading1(true);
    setLoading2(true);

    setTabChange(temp);
    if (temp === "general") {
      setLoading1(false);
      setLoading2(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_ROLES_DETAILS_BY_ID}${role_id}`,
      setRoleData
    );
  }, []);

  useEffect(() => {
    if (roleData) {
      setName(roleData?.role_name);
    }
  }, [roleData]);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (role_id !== undefined) {
      formData.append("role_id", role_id);
    }

    if (tabChange === "general") {
      formData.append("new_role_name", name);
    }

    try {
      let endpoint = "";

      if (tabChange === "general") {
        endpoint = `${process.env.REACT_APP_ROLES_EDIT_USER_ROLE}`;
      }

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshUserData((prevRefresh: any) => prevRefresh + 1);
      toast.success("Role has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while editing role", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className=" absolute">
          <Link to={"/roles"}>
            <div className=" flex flex-row items-center">
              <IoChevronBackSharp title="roles" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          Edit {tabChange} Section for {name} role
        </p>
        <div className=" flex flex-row justify-between gap-4 w-full">
          <div className=" flex flex-col gap-3 max-w-[200px] w-full pt-8">
            <button
              onClick={() => handleTabClick("general")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "general"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300 font-semibold"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Role Info</p>
              <IoIosArrowForward />
            </button>
          </div>

          {loading1 === true || loading2 === true ? (
            <div className=" w-full flex justify-center items-center pt-16">
              <ImSpinner2 size={25} className="animate-spin" />
            </div>
          ) : (
            <>
              {tabChange === "general" ? (
                <form className=" w-full" onSubmit={handleEditSubmit}>
                  <div className=" flex flex-col gap-4">
                    <div className=" grid grid-cols-2 gap-2">
                      <div className="flex flex-col">
                        <label className="pb-2 text-[20px]">Role Name:</label>
                        <input
                          disabled={
                            name === "admin" ||
                            name === "student" ||
                            name === "teacher"
                          }
                          required
                          type="text"
                          value={name}
                          onChange={handleNameChange}
                          className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                        />
                      </div>
                    </div>

                    {name === "admin" ||
                    name === "student" ||
                    name === "teacher" ? null : (
                      <div className="mt-4 flex justify-center">
                        <button
                          type="submit"
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default EditRole;
