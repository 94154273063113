import { useEffect, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";

function EditPlan({ setRefreshData }: any) {
  interface PlanData {
    id: string;
    name: string;
    price: number;
    max_input_tokens_per_day: number;
    max_output_tokens_per_day: number;
  }

  const { plan_name } = useParams();
  const { state } = useLocation();

  const [name, setName] = useState("");
  const [price, setPrice] = useState<number | null>(0);
  const [maxInputTokens, setMaxInputTokens] = useState<number | null>(0);
  const [maxOutputTokens, setMaxOutputTokens] = useState<number | null>(0);

  const [planId, setPlanId] = useState<string>(() => {
    const storedPlanId = window.localStorage.getItem("planId") || "";
    return (state && state.plan_id) || storedPlanId;
  });

  const [planData, setPlanData] = useState<PlanData>();

  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const handleTabClick = async (temp: any) => {
    setTabChange(temp);
  };

  useEffect(() => {
    if (tabChange === "general") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_PLAN_BY_ID}${planId}`,
        setPlanData
      );
    }
    setLoading1(false);
  }, [refresh, setRefreshData, tabChange]);

  useEffect(() => {
    if (planData) {
      setName(planData.name);
      setPrice(planData.price);
      setMaxInputTokens(planData.max_input_tokens_per_day);
      setMaxOutputTokens(planData.max_output_tokens_per_day);
    }
  }, [planData]);

  useEffect(() => {
    if (price && price < 0) {
      setPrice(0);
    }
  }, [price]);

  useEffect(() => {
    if (maxInputTokens && maxInputTokens < 0) {
      setMaxInputTokens(0);
    }
  }, [maxInputTokens]);

  useEffect(() => {
    if (maxOutputTokens && maxOutputTokens < 0) {
      setMaxOutputTokens(0);
    }
  }, [maxOutputTokens]);

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (planId !== undefined) {
      formData.append("id", planId);
    }

    if (tabChange === "general") {
      formData.append("name", name);
      if (price !== null) {
        if (price <= 0) {
          toast.error("Price must be a positive number!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append("price", price.toString());
        }
      }
      if (maxInputTokens !== null) {
        if (maxInputTokens <= 0) {
          toast.error(
            "Maximum number of input tokens per day must be a positive number!",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "dark",
            }
          );
          return;
        } else {
          formData.append("input_tokens", maxInputTokens.toString());
        }
      }
      if (maxOutputTokens !== null) {
        if (maxOutputTokens <= 0) {
          toast.error(
            "Maximum number of output tokens per day must be a positive number!",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "dark",
            }
          );
          return;
        } else {
          formData.append("output_tokens", maxOutputTokens.toString());
        }
      }
    }

    try {
      let endpoint = "";

      if (tabChange === "general") {
        endpoint = `${process.env.REACT_APP_EDIT_PLAN}${planId}`;
      }

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefreshData((prevRefresh: any) => prevRefresh + 1);
      setRefresh((prevRefresh: any) => prevRefresh + 1);

      toast.success("Plan has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        // Fallback for when error is not an instance of Error
        toast.error("An error occurred while editing Plan", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute">
          <Link to={"/plans"}>
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="Plan" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          Edit {tabChange} Section for {planData?.name} Plan
        </p>
        <div className="flex flex-row justify-between gap-4 w-full">
          <div className="flex flex-col gap-3 max-w-[200px] w-full pt-8">
            <button
              onClick={() => handleTabClick("general")}
              className={`flex flex-row justify-between items-center mr-2 px-2 py-2 border-b text-gray-800 border-gray-500 ${
                tabChange === "general"
                  ? "border-[#4D30B5] hover:border-[#1b1436] duration-300 font-semibold"
                  : " border-[#1b1436]"
              }`}
            >
              <p>Plan Info</p>
              <IoIosArrowForward />
            </button>
          </div>

          {loading1 === true ? (
            <div className="w-full flex justify-center items-center pt-16">
              <ImSpinner2 size={25} className="animate-spin" />
            </div>
          ) : (
            <>
              {tabChange === "general" && (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="grid grid-cols-2 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">Plan Name:</label>
                          <input
                            required
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">Price:</label>
                          <input
                            required
                            type="number"
                            value={price ?? ""}
                            onChange={(e) =>
                              setPrice(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-3">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Maximum Input Tokens Per Day:
                          </label>
                          <input
                            required
                            type="number"
                            value={maxInputTokens ?? ""}
                            onChange={(e) =>
                              setMaxInputTokens(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Maximum Output Tokens Per Day:
                          </label>
                          <input
                            required
                            type="number"
                            value={maxOutputTokens ?? ""}
                            onChange={(e) =>
                              setMaxOutputTokens(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default EditPlan;
