import { toast } from "react-toastify";
import api from "../../../../api";
import { useState } from "react";

function DeleteContractSubscription({
  handleDeleteClick,
  closeDeleteContractSubscription,
  idEdit,
  nameEdit,
  setRefresh,
  setRefreshData,
}: any) {
  const [id] = useState(idEdit);

  const deleteFiles = async (e: any) => {
    try {
      await api.post(
        `${process.env.REACT_APP_DELETE_SCHOOL_SUBSCRIPTION}${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setRefresh((prevRefresh: any) => prevRefresh + 1);
      setRefreshData((prevRefresh: any) => prevRefresh + 1);
      closeDeleteContractSubscription(e);
      toast.success("Contract Subscription Deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while deleting Contract Subscription", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  return (
    <div
      onMouseDown={handleDeleteClick}
      role="button"
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md cursor-default"
    >
      <div className="bg-[#EDEAF8] rounded-lg shadow-2xl w-[420px]">
        <div className=" flex flex-col ">
          <p className="p-6 font-bold">
            Delete {nameEdit} Contract Subscription?
          </p>
          <div className=" border-b" />
          <div className=" flex flex-row justify-end gap-4 p-6">
            <button
              onClick={closeDeleteContractSubscription}
              className=" bg-white rounded-md p-2 hover:bg-[#b5b1c4]  duration-300"
            >
              Cancel
            </button>
            <button
              onClick={(e: any) => {
                deleteFiles(e);
              }}
              className=" bg-red-700 rounded-md p-2 text-white hover:bg-red-900 duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteContractSubscription;
