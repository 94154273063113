import api from "../../../../api";

function DeleteAllRooms({
  handleDeleteButtonClick,
  clientId,
  setHistoryData,
  setMessageList,
  setSelectedRoomId,
  setRefreshRooms,
  setOpenDeleteAll,
  setMoreOptions,
  setFile,
}: any) {
  const deletAllChats = async () => {
    if (clientId !== null) {
      const formData = new FormData();
      formData.append("user_id", clientId);

      try {
        await api.post(`${process.env.REACT_APP_DELETE_ALL_ROOMS}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {}
      setRefreshRooms((prevRefresh: any) => prevRefresh + 1);
      setSelectedRoomId(Math.floor(Math.random() * 1e9));
      setMessageList([]);
      setHistoryData([]);
      setOpenDeleteAll(false);
      setMoreOptions(false);
      setFile(null);
    }
  };

  return (
    <div
      onClick={handleDeleteButtonClick}
      role="button"
      className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md"
    >
      <div className="bg-[#EDEAF8] rounded-lg shadow-2xl w-[420px]">
        <div className=" flex flex-col ">
          <p className="p-6 font-bold">Delete All Rooms?</p>
          <div className=" border-b" />
          <div className=" flex flex-row justify-end gap-4 p-6">
            <button
              onClick={handleDeleteButtonClick}
              className=" bg-white rounded-md p-2 hover:bg-[#b5b1c4]  duration-300"
            >
              Cancel
            </button>
            <button
              onClick={deletAllChats}
              className=" bg-red-700 rounded-md p-2 text-white hover:bg-red-900 duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAllRooms;
