import React, { useEffect, useRef, useState } from "react";
import { IoChevronBackSharp, IoCloseSharp } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import api from "../../../../api";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import { RiDeleteBin5Line } from "react-icons/ri";
import User from "../../../../../Assets/ChatPerson.png";
import boxMind from "../../../../../Assets/OkationLogo.png";
import { CiImageOn } from "react-icons/ci";
import { io } from "socket.io-client";

let currentInputVar = 0;

const Quiz = ({ userData }: any) => {
  interface Quiz {
    quiz_id: string;
    chapter_id: number;
    chapter_title: string;
    score: string;
    quiz_taken: string;
    questions: {
      is_correct: string;
      selected_option: string;
      question_id: string;
      question_text: string;
      correct_option: string;
      optionss: string[];
    }[];
  }

  interface Message {
    type: "input" | "response" | "clipboard";
    message: JSX.Element | string;
    image?: string;
  }

  interface History {
    room_id: number;
    question: string;
    answer: string;
    image: string;
  }

  interface ChaptersData {
    id: string;
    title: string;
    description: string;
    video_order: number;
    explainer_video: VideoExplainer[];
    live_video: LiveVideo[];
    content: string;
    simulation: string;
    guide: string;
    teacher_guide: string;
    student_guide: string;
    include_chat: string;
    include_quiz: string;
    include_live_video: string;
    include_explainer_video: string;
    include_simulation: string;
    story: string;
  }

  interface VideoExplainer {
    name: string;
    link: string;
    transcript: string;
    video_id: string;
  }

  interface LiveVideo {
    name: string;
    link: string;
    transcript: string;
    video_id: string;
  }

  const { course_name } = useParams();
  const { chapter_name } = useParams();
  const { state } = useLocation();
  const user_id = localStorage.getItem("user_id") || "default_user";
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [chapterId, setChapterId] = useState<string>(() => {
    const storedChapterId = window.localStorage.getItem("chapterId") || "";
    return (state && state.chapter_id) || storedChapterId;
  });

  const [refresh, setRefresh] = useState(0);
  const [currentDatabaseId, setCurrentDatabaseId] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [historyData, setHistoryData] = useState<History[]>([]);
  const [refreshChat, setRefreshChat] = useState(0);
  const [loadingChat, setLoadingChat] = useState(false);
  const [pendingImage, setPendingImage] = useState<string | null>(null);
  const [chapterData, setChapterData] = useState<ChaptersData | null>(null);
  const [quizTaken, setQuizTaken] = useState(false);
  const [roomId, setRoomId] = useState(-1);

  const combinedId = `${user_id}000${chapterId}`;
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isScrolledUp, setIsScrolledUp] = useState<boolean>(false);

  const [quiz, setQuiz] = useState<Quiz | null>(null);

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_CHAPTER_BY_ID}${chapterId}`,
      setChapterData
    );
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_QUIZ_BY_ID}${chapterId}`,
      setQuiz
    );
  }, [user_id, refresh]);

  useEffect(() => {
    if (quiz?.quiz_taken === "yes") {
      setQuizTaken(true);
    }
  }, [quiz]);

  const handleAnswerSelect = (question_id: number, option: string) => {
    if (quiz) {
      const updatedQuestions = quiz.questions.map((question, index) => {
        if (index === question_id) {
          return {
            ...question,
            selected_option: option,
          };
        }
        return question;
      });

      setQuiz({
        ...quiz,
        questions: updatedQuestions,
      });
    }
  };

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (quiz?.questions) {
      for (let i = 0; i < quiz.questions.length; i++) {
        const question = quiz.questions[i];
        if (question.selected_option === "") {
          return toast.error(
            `you need to asnwer all the questions before submitting your answer! (Question ${
              i + 1
            })`,
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: "dark",
            }
          );
        }
      }
    }

    try {
      const selectedOptions = quiz?.questions.map((question) => ({
        question_id: question.question_id,
        selected_option: question.selected_option,
      }));

      const formData = new FormData();
      formData.append("data", JSON.stringify(selectedOptions));
      formData.append("question_id", JSON.stringify(currentDatabaseId));

      await api.post(`${process.env.REACT_APP_SUBMIT_QUIZ}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);

      toast.success("Quiz has been submitted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while submitting the Quiz: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while submitting the Quiz", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error submitting the Quiz:", error);
    }
  };

  const retakeQuiz = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("quiz_id", quiz?.quiz_id ?? "");

      await api.post(`${process.env.REACT_APP_RESET_QUIZ}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRefresh((prevRefresh: any) => prevRefresh + 1);

      toast.success("Quiz has been reset successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while resetting the Quiz: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while resetting the Quiz", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error resetting the Quiz:", error);
    }
  };

  const handleDeleteImage = () => {
    setPendingImage(null);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPendingImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const scrollToBottom = () => {
    if (!isScrolledUp) {
      const div = document.getElementById("div-of-scroll");
      if (div !== null) {
        div.scrollTo({
          top: div.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
    const handleScroll = () => {
      const div = chatContainerRef.current;

      setIsScrolledUp(true);
      if (div && div.scrollTop + div.clientHeight > div.scrollHeight - 5) {
        setIsScrolledUp(false);
      }
    };

    const div = chatContainerRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);

      return () => {
        div.removeEventListener("scroll", handleScroll);
      };
    }
  }, [messages, historyData]);

  //delete room
  const handleDelete = async (e: any) => {
    const formData = new FormData();
    formData.append("room_id", String(roomId));

    try {
      await api.post(`${process.env.REACT_APP_DELETE_CHAT}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setRoomId(Math.round(Math.random() * 1e9));
      setMessages([]);
      setHistoryData([]);
      setRefreshChat((prevRefresh: any) => prevRefresh + 1);
      toast.success("Room has been Deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      toast.error("An error occurred while deleting the room", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
      console.log(error);
    }
  };

  //get room id
  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();
        formData.append("user_id", user_id);
        formData.append("quiz_id", quiz?.quiz_id ?? "");
        formData.append(
          "question_id",
          quiz?.questions[currentDatabaseId]?.question_id ?? ""
        );
        // formData.append("chapter_id", chapterData?.id ?? "");
        formData.append("type", "quiz");

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ROOM_ID}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        if (data !== null && data !== undefined) {
          setRoomId(data);
        } else {
          setRoomId(Math.round(Math.random() * 1e9));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (roomId === -1 && showChat) {
      fetchData1();
    }
  }, [showChat, roomId]);

  //open socket on room id
  useEffect(() => {
    if (roomId !== -1 && showChat) {
      const socket = io(`${process.env.REACT_APP_QUESTIONS}`);
      socket.on("connect", () => {
        socket.emit("join", { room_id: roomId });
      });
      socket.on("token", (token: { data: string }) => {
        const responseMessage: Message = {
          type: "response",
          message: token.data,
        };

        var div = document.getElementById(`text-wrap-${currentInputVar}`);

        if (div) {
          div.innerHTML += token.data;
        }

        setMessages((prevMessageList) => [...prevMessageList, responseMessage]);
      });

      return () => {
        socket.off("token");
        socket.close();
      };
    }
  }, [showChat, roomId]);

  //get history data from room id
  useEffect(() => {
    async function fetchData1() {
      try {
        const formData = new FormData();
        // formData.append("user_id", user_id);
        // formData.append("quiz_id", quiz?.quiz_id ?? "");
        // formData.append(
        //   "question_id",
        //   quiz?.questions[currentDatabaseId]?.question_id ?? ""
        // );
        // formData.append("chapter_id", chapterData?.id ?? "");
        // formData.append("type", "quiz");
        formData.append("room_id", String(roomId));

        const response = await api.post(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_HISTORY}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const data = response.data;
        setHistoryData(data);
        setInputMessage("");
        currentInputVar = response.data.length - 1;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (roomId !== -1 && showChat) {
      fetchData1();
    }
  }, [showChat, roomId]);

  //send question
  const sendQuestion = () => {
    setIsScrolledUp(false);
    if (inputMessage.trim() === "") return;

    setLoadingChat(true);

    currentInputVar = messages.length;

    const newInputMessage: Message = {
      type: "input",
      message: (
        <>
          <div className="flex justify-end w-full">
            <div className="pb-4 pr-2 flex flex-row gap-2 items-center">
              <div className="h-full">
                <img
                  src={User}
                  style={{ width: 45, height: 35 }}
                  alt={"User"}
                  loading="lazy"
                />
              </div>
              <div className="w-full">{inputMessage}</div>
            </div>
          </div>
        </>
      ),
    };

    setMessages((prevMessageList) => [...prevMessageList, newInputMessage]);
    setInputMessage("");
    // setSelectedImage(null);

    const formData = new FormData();
    formData.append("message", inputMessage);
    formData.append("user_id", user_id);
    formData.append("type", "quiz");

    formData.append("room_id", roomId.toString());
    formData.append(
      "question_id",
      quiz?.questions[currentDatabaseId]?.question_id ?? ""
    );

    formData.append(
      "quiz_answer",
      quiz?.questions[currentDatabaseId]?.selected_option ?? ""
    );

    formData.append(
      "quiz_question",
      quiz?.questions[currentDatabaseId]?.question_text ?? ""
    );

    formData.append(
      "correct_answer",
      quiz?.questions[currentDatabaseId]?.correct_option ?? ""
    );

    formData.append(
      "quiz_options",
      JSON.stringify(quiz?.questions[currentDatabaseId]?.optionss ?? [])
    );

    if (chapterData) {
      formData.append("chapter_id", chapterData.id);
    }

    api
      .post(`${process.env.REACT_APP_ANSWERS}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoadingChat(false);

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoadingChat(false);

        var imageRotate = document.getElementById(`logo-${currentInputVar}`);

        if (imageRotate === null) {
        } else {
          imageRotate.className = "";
        }

        var currentdiv = document.getElementById(
          `text-wrap-${currentInputVar}`
        );
        const res = document.createElement("span");
        res.classList.add("errorbox");
        res.innerHTML = error.response.data.message;
        currentdiv?.appendChild(res);
      });
  };

  return (
    <section
      className="overflow-hidden h-screen"
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center justify-between gap-1">
        <div className="flex flex-row items-center gap-1">
          <Link
            state={{ course_id: courseId, chapter_id: chapterId }}
            className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg"
            to={`/courses/view-course/${course_name}/view-chapter/${chapter_name}`}
          >
            <IoChevronBackSharp title="Profile" size={17} />
          </Link>
          <p className="font-bold text-[20px]">MCQ:</p>
        </div>
        <div className=" flex flex-row gap-2 items-center">
          <div className="text-[20px]">
            <span className=" font-bold">Score:</span> {quiz?.score}%
          </div>
          {userData?.roles.some((role: string) =>
            ["teacher", "superadmin", "schooladmin"].includes(role)
          ) && (
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  retakeQuiz(e);
                }}
                className={`bg-[#4D30B5] hover:bg-[#1b1436] text-white px-4 py-2 rounded duration-300 flex flex-row gap-2 items-center`}
              >
                Retake Quiz
              </button>
            </div>
          )}
          {quizTaken && (
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowChat(!showChat);
                  setRoomId(-1);
                  setMessages([]);
                }}
                className={`bg-[#4D30B5] hover:bg-[#1b1436] text-white px-4 py-2 rounded duration-300 flex flex-row gap-2 items-center`}
              >
                Chat
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="relative h-screen flex">
        <form
          className="relative h-screen w-full"
          style={{ maxHeight: "calc(100vh - 160px)" }}
          onSubmit={handleUploadSubmit}
        >
          <div
            className={`p-4  gap-4 overflow-scroll h-screen`}
            style={{ maxHeight: "calc(100vh - 180px)", flex: 1 }}
          >
            {quiz?.questions && (
              <div className="mb-4">
                <div className="text-[20px] font-semibold mb-2">
                  Q{currentDatabaseId + 1}.{" "}
                  {quiz?.questions[currentDatabaseId].question_text}{" "}
                  {quiz?.questions[currentDatabaseId].is_correct === "True" && (
                    <span className="text-green-500">(True)</span>
                  )}
                  {quiz?.questions[currentDatabaseId].is_correct ===
                    "False" && <span className="text-red-500">(False)</span>}
                </div>
                {quiz?.questions[currentDatabaseId].optionss.map(
                  (option, optionIndex) => (
                    <label key={optionIndex} className="block text-[18px]">
                      <input
                        disabled={
                          quiz?.questions[currentDatabaseId].is_correct !==
                          "pending"
                        }
                        type="radio"
                        name={`question-${currentDatabaseId}`}
                        value={option}
                        checked={
                          quiz?.questions[currentDatabaseId].selected_option ===
                          option
                        }
                        onChange={() =>
                          handleAnswerSelect(currentDatabaseId, option)
                        }
                      />
                      <span
                        className={`ml-2 
                    ${
                      quiz?.questions[currentDatabaseId].is_correct ===
                        "True" &&
                      option ===
                        quiz?.questions[currentDatabaseId].correct_option &&
                      "text-green-500"
                    } 
                    ${
                      quiz?.questions[currentDatabaseId].is_correct ===
                        "False" &&
                      option ===
                        quiz?.questions[currentDatabaseId].selected_option &&
                      "text-red-500"
                    } `}
                      >
                        {option}
                      </span>
                    </label>
                  )
                )}
              </div>
            )}
          </div>
          <div
            className={`${
              showChat ? "grid-cols-6" : "grid-cols-8"
            } grid absolute w-full bottom-0 `}
          >
            <div className="col-span-1 flex flex-row justify-center content-center">
              {currentDatabaseId !== 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setRoomId(-1);
                    setMessages([]);
                    setCurrentDatabaseId(currentDatabaseId - 1);
                  }}
                  className={`bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300 flex flex-row gap-2 items-center `}
                >
                  <FaArrowLeft title="left" size={25} />
                  Previous
                </button>
              )}
            </div>
            <div className={`${showChat ? "col-span-1" : "col-span-2"} `}></div>
            <div className="col-span-2 flex flex-row justify-center items-center">
              <button
                type="submit"
                className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300 "
              >
                Submit Answer
              </button>
            </div>
            <div className={`${showChat ? "col-span-1" : "col-span-2"} `}></div>
            <div className="col-span-1 flex flex-row justify-center content-center">
              {currentDatabaseId + 1 !== quiz?.questions.length && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setMessages([]);
                    setRoomId(-1);
                    setCurrentDatabaseId(currentDatabaseId + 1);
                  }}
                  className={`bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300 flex flex-row gap-2 items-center`}
                >
                  Next
                  <FaArrowRight title="left" size={25} />
                </button>
              )}
            </div>
          </div>
        </form>
        {showChat && (
          <div className={`px-4 flex flex-col w-1/2 border-l mt-5`}>
            <div className="flex flex-row justify-between items-center">
              <h2 className="text-xl font-bold ">Answer Guide</h2>
              <button
                onClick={(e: any) => {
                  handleDelete(e);
                }}
              >
                <RiDeleteBin5Line
                  size={25}
                  className=" text-red-600 hover:text-red-800 duration-300"
                />
              </button>
            </div>
            <div
              className="overflow-auto mb-4"
              style={{ height: "calc(100vh - 300px)" }}
              ref={chatContainerRef}
              id="div-of-scroll"
            >
              {historyData?.map((data, index) => (
                <div key={index}>
                  <div className="flex justify-end w-full">
                    <div className="pb-4 pr-2 flex flex-row gap-2 items-center">
                      <div className="h-full">
                        <img
                          src={User}
                          loading="lazy"
                          width={45}
                          height={35}
                          alt="User"
                        />
                      </div>
                      <div className=" flex flex-col gap-2 mt-1 w-full">
                        <p>{data?.question}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mb-5">
                    <div className={`flex flex-row gap-2 mt-2 w-full`}>
                      <div className=" h-full">
                        <img
                          src={boxMind}
                          alt="boxMind"
                          loading="lazy"
                          width={35}
                          height={35}
                          className=""
                        />
                      </div>
                      <div className=" w-full whitespace-break-spaces">
                        {data.answer}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <>
                {messages?.map((item, i) =>
                  item.type === "input" ? (
                    <div key={i}>
                      <div className=" flex justify-end">{item?.message}</div>
                      <div>
                        <div className=" flex flex-row gap-2 mt-2">
                          <div>
                            <img
                              src={boxMind}
                              alt="boxMind"
                              width={35}
                              loading="lazy"
                              height={35}
                              className=" animate-spin"
                              id={`logo-${i}`}
                            />
                          </div>
                          <div className=" w-full whitespace-break-spaces">
                            <p id={`text-wrap-${i}`}></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </>
            </div>
            <div className="flex flex-row items-center bg-white border rounded-md outline-none relative">
              {/* <div>
                <label htmlFor="imageInput" className="cursor-pointer">
                  <input
                    type="file"
                    id="imageInput"
                    accept=".png, .jpg, .jpeg"
                    className="hidden"
                    multiple={false}
                    onChange={handleImageUpload}
                  />
                  <div className="ml-2 rounded-md cursor-pointer">
                    <CiImageOn size={20} />
                  </div>
                </label>
              </div> */}
              <input
                type="text"
                value={inputMessage}
                className="rounded p-2 outline-none w-full"
                placeholder="Type your message..."
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !loadingChat && !e.shiftKey) {
                    sendQuestion();
                    e.preventDefault();
                  }
                }}
                onChange={(e) => setInputMessage(e.target.value)}
              />
              {pendingImage && (
                <div className=" bottom-12 rounded-lg w-full absolute bg-[#F8FBFD]">
                  <div className=" relative">
                    <img
                      src={pendingImage}
                      alt="Pending Upload"
                      className="w-[100px] h-[100px] rounded-md object-cover"
                    />
                    <button
                      onClick={handleDeleteImage}
                      className=" rounded-md cursor-pointer absolute top-1 left-1 bg-red-600 text-white p-1"
                    >
                      <IoCloseSharp />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Quiz;
