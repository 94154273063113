import axios, { AxiosError } from "axios";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import JoditEditor from "jodit-react";
import config from "../Config";

function EditContent({ setRefresh }: any) {
  interface ContentData {
    id: string;
    name: string;
    type: string;
    link: string;
    description: string;
  }

  const { course_name } = useParams();
  const { chapter_name } = useParams();
  const { content_name } = useParams();

  const [contentData, setContentData] = useState<ContentData | null>(null);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [courseId, setCourseId] = useState<string>(() => {
    const storedCourseId = window.localStorage.getItem("courseId") || "";
    return (state && state.course_id) || storedCourseId;
  });
  const [chapterId, setChapterId] = useState<string>(() => {
    const storedChapterId = window.localStorage.getItem("chapterId") || "";
    return (state && state.chapter_id) || storedChapterId;
  });
  const [contentId, setContentId] = useState<string>(() => {
    const storedContentId = window.localStorage.getItem("contentId") || "";
    return (state && state.content_id) || storedContentId;
  });
  const [type, setType] = useState("file");
  const [link, setLink] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const editor = useRef(null);

  const user_id = localStorage.getItem("user_id") || "default_user";

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  useEffect(() => {
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_CONTENT_BY_ID}${contentId}`,
      setContentData
    );
  }, []);

  useEffect(() => {
    if (contentData) {
      setName(contentData?.name);
      setType(contentData?.type);
      setDescription(contentData?.description);
      setLink(contentData?.link);
      setLoading(false);
    }
  }, [contentData]);

  const handleUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("type", type);
      formData.append("description", description);

      if (type === "link") {
        formData.append("link", link);
      } else {
        if (file) {
          formData.append("file", file);
        } else {
          formData.append("file", "");
        }
      }

      await api.post(
        `${process.env.REACT_APP_EDIT_CONTENT}${contentId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setRefresh((prevRefresh: any) => prevRefresh + 1);
      window.localStorage.setItem("courseId", courseId);
      window.localStorage.setItem("chapterId", chapterId);

      window.location.href = `/courses/edit-course/${course_name}/edit-chapter/${chapter_name}`;
      toast.success("Content has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data || axiosError.message;

        toast.error(
          `An error occurred while editing the Content: ${errorMessage}`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          }
        );
      } else {
        toast.error("An unexpected error occurred while editing the Content", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }
      console.error("Error editing the Content:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center mt-10">
        <ImSpinner size={30} className=" animate-spin" />
      </div>
    );
  }

  return (
    <section
      className="overflow-hidden h-screen "
      style={{ maxHeight: "calc(100vh - 50px)" }}
    >
      <div className="flex flex-row items-center w-[210px]  gap-1">
        <Link
          state={{ course_id: courseId, chapter_id: chapterId }}
          className="px-2 hover:-ml-1 hover:pr-3 duration-200 rosunded-lg "
          to={`/courses/edit-course/${course_name}/edit-chapter/${chapter_name}`}
        >
          <IoChevronBackSharp title="Profile" size={17} />
        </Link>
        <p className="font-bold text-[20px]">Edit Content:</p>
      </div>

      <form
        onSubmit={handleUploadSubmit}
        className="relative h-screen"
        style={{ maxHeight: "calc(100vh - 170px)" }}
      >
        <div
          className="p-4 flex flex-col gap-4 overflow-scroll h-screen "
          style={{ maxHeight: "calc(100vh - 210px)" }}
        >
          {type === "link" ? (
            <>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Link Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">Link:</label>
                <input
                  required
                  type="text"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col mb-10">
                <label className="pb-2 text-[20px]">Description:</label>
                {/* <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                /> */}
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={description}
                  onChange={setDescription}
                />
              </div>
            </>
          ) : (
            <>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">File Name:</label>
                <input
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className=" border-[2px] text-black  outline-none p-1 rounded-lg"
                />
              </div>
              <div className=" flex flex-col ">
                <label className="pb-2 text-[20px]">File:</label>
                <input required type="file" onChange={handleFileChange} />
              </div>
              <div className=" flex flex-col mb-10">
                <label className="pb-2 text-[20px]">Description:</label>
                <JoditEditor
                  ref={editor}
                  config={config}
                  value={description}
                  onChange={setDescription}
                />
              </div>
            </>
          )}
        </div>
        <div className=" flex justify-center absolute w-full bottom-0 ">
          <button
            type="submit"
            className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
          >
            Update
          </button>
        </div>
      </form>
    </section>
  );
}

export default EditContent;
