import { useEffect, useRef, useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";
import api from "../../../../api";
import { Link, useLocation, useParams } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoChevronBackSharp } from "react-icons/io5";
import { fetchData } from "../../../../GlobalFunctions";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import DeleteContractLevel from "../ContractLevels/DeleteContractLevel";
import { MenuItem, Select } from "@mui/material";
import DeleteContractSubscription from "../ContractSubscription/DeleteContractSubscription";
import JoditEditor from "jodit-react";
import config from "../Config";

let currentIdDatabase = 0;
let current_contract_level_edit = "";
let current_contract_subscription_edit = "";

function EditContract({ setRefreshData }: any) {
  interface ContractData {
    id: string;
    name: string;
    start_date: string;
    end_date: string;
    edit_deadline: string;
    terms: string;
    total_price: number;
  }

  interface LevelsData {
    id: string;
    name: string;
    status: string;
  }

  interface ActiveLevelsData {
    level_id: string;
    level_name: string;
    number_of_students: string;
    assigned_seats: string;
    remaining_seats: string;
    total_level_price: string;
    contract_level_id: string;
  }

  interface ActiveLevel {
    level_id: string;
    level_name: string;
    number_of_students: number;
    total_level_price: number;
    contract_level_id: string;
  }

  interface SubscriptionsData {
    id: string;
    name: string;
    status: string;
  }

  interface ActiveSubscriptionsData {
    id: string;
    subscription_plan: string;
    subscriptions_purchased: number;
    active_subscriptions: number;
    total_plan_price: number;
    start_date: string;
    end_date: string;
    status: string;
  }

  interface ActiveSubscription {
    id: string;
    subscription_plan_name: string;
    subscription_plan_id: string;
    subscriptions_purchased: number;
    total_plan_price: string;
    start_date: string;
    end_date: string;
  }

  const { contract_name } = useParams();
  const { school_name } = useParams();
  const { state } = useLocation();
  const [schoolId, setSchoolId] = useState<string>(() => {
    const storedSchoolId = window.localStorage.getItem("schoolId") || "";
    return (state && state.school_id) || storedSchoolId;
  });
  const [contractId, setContractId] = useState<string>(() => {
    const storedContractId = window.localStorage.getItem("contractId") || "";
    return (state && state.contract_id) || storedContractId;
  });
  const editor = useRef(null);

  //general consts
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [editDeadline, setEditDeadline] = useState<string | undefined>(
    undefined
  );
  const [terms, setTerms] = useState<string>("");
  const [price, setPrice] = useState<number>();

  //contract consts
  const [contractData, setContractData] = useState<ContractData>();

  const [tabChange, setTabChange] = useState("general");
  const [loading1, setLoading1] = useState(false);
  const [refresh, setRefresh] = useState(0);

  //levels consts
  const [levelsData, setLevelsData] = useState<LevelsData[]>([]);
  const [activeLevelsData, setActiveLevelsData] = useState<ActiveLevelsData[]>(
    []
  );
  const [isDeleteContractLevelOpen, setIsDeleteContractLevelOpen] =
    useState(false);
  const [selectedLevel, setSelectedLevel] = useState("-1");
  const [numberOfLevelStudents, setNumberOfLevelStudents] = useState<
    number | null
  >(0);
  const [LevelPrice, setLevelPrice] = useState<number | null>(0);

  //edit level
  const [activeLevel, setActiveLevel] = useState<ActiveLevel | null>(null);
  const [editLevelName, setEditLevelName] = useState("");

  //Subscriptions consts
  const [subscriptionsData, setSubscriptionsData] = useState<
    SubscriptionsData[]
  >([]);
  const [activeSubscriptionsData, setActiveSubscriptionsData] = useState<
    ActiveSubscriptionsData[]
  >([]);
  const [
    isDeleteContractSubscriptionOpen,
    setIsDeleteContractSubscriptionOpen,
  ] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState("-1");
  const [numberOfSubscribers, setNumberOfSubscribers] = useState<number | null>(
    0
  );
  const [subscriptionStartDate, setSubscriptionStartDate] = useState<
    string | undefined
  >(undefined);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<
    string | undefined
  >(undefined);

  //edit level
  const [activeSubscription, setActiveSubscription] =
    useState<ActiveSubscription | null>(null);
  const [editSubscriptionName, setEditSubscriptionName] = useState("");
  const [editSubscriptionPrice, setEditSubscriptionPrice] = useState("");

  const openDeleteContractLevel = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteContractLevelOpen(true);
  };

  const closeDeleteContractLevel = () => {
    setIsDeleteContractLevelOpen(false);
  };

  const handleDeleteLevelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteContractLevel();
    }
  };

  const openDeleteContractSubscription = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteContractSubscriptionOpen(true);
  };

  const closeDeleteContractSubscription = () => {
    setIsDeleteContractSubscriptionOpen(false);
  };

  const handleDeleteSubscriptionClick = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (e.target === e.currentTarget) {
      closeDeleteContractSubscription();
    }
  };

  const handleTabClick = async (temp: any) => {
    setTabChange(temp);
  };

  useEffect(() => {
    //reset levels
    setSelectedLevel("-1");
    setNumberOfLevelStudents(0);
    setLevelPrice(0);
    setActiveLevelsData([]);
    setActiveLevel(null);
    setEditLevelName("");

    //reset subscriptions
    setSelectedSubscription("-1");
    setSubscriptionsData([]);
    setNumberOfSubscribers(0);
    setActiveSubscription(null);
    setActiveSubscriptionsData([]);
    setEditSubscriptionName("");
    setSubscriptionStartDate(undefined);
    setSubscriptionEndDate(undefined);

    if (tabChange === "general") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_CONTRACT_BY_ID}${contractId}`,
        setContractData
      );
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_CONTRACT_LEVELS}${contractId}`,
        setActiveLevelsData
      );
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SCHOOL_SUBSCRIPTIONS}${schoolId}`,
        setActiveSubscriptionsData
      );
    } else if (tabChange === "addlevel") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_LEVELS_CONTRACT}${contractId}`,
        setLevelsData
      );
    } else if (tabChange === "editlevel") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_CONTRACT_LEVEL_BY_ID}${current_contract_level_edit}`,
        setActiveLevel
      );
    } else if (tabChange === "addsubscription") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_SUBSCRIPTION_NAMES}${schoolId}`,
        setSubscriptionsData
      );
    } else if (tabChange === "editsubscription") {
      fetchData(
        `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_SCHOOL_SUBSCRIPTION_BY_ID}${current_contract_subscription_edit}`,
        setActiveSubscription
      );
    }
    setLoading1(false);
  }, [tabChange, refresh]);

  useEffect(() => {
    if (contractData) {
      setName(contractData.name);
      setTerms(contractData.terms);
      setPrice(contractData.total_price);
      const convertToDateInputFormat = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      setStartDate(convertToDateInputFormat(contractData.start_date));
      setEndDate(convertToDateInputFormat(contractData.end_date));
      setEditDeadline(convertToDateInputFormat(contractData.edit_deadline));
    }
  }, [contractData]);

  useEffect(() => {
    if (activeLevel) {
      setEditLevelName(activeLevel.level_name);
      setLevelPrice(activeLevel.total_level_price);
      setNumberOfLevelStudents(activeLevel.number_of_students);
    }
  }, [activeLevel]);

  useEffect(() => {
    if (activeSubscription) {
      setEditSubscriptionName(activeSubscription?.subscription_plan_name);
      setEditSubscriptionPrice(activeSubscription?.total_plan_price);
      setNumberOfSubscribers(activeSubscription?.subscriptions_purchased);
      const convertToDateInputFormat = (dateString: string) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      setSubscriptionStartDate(
        convertToDateInputFormat(activeSubscription.start_date)
      );
      setSubscriptionEndDate(
        convertToDateInputFormat(activeSubscription.end_date)
      );
    }
  }, [activeSubscription]);

  useEffect(() => {
    if (numberOfLevelStudents && numberOfLevelStudents < 0) {
      setNumberOfLevelStudents(0);
    }
  }, [numberOfLevelStudents]);

  useEffect(() => {
    if (LevelPrice && LevelPrice < 0) {
      setLevelPrice(0);
    }
  }, [LevelPrice]);

  useEffect(() => {
    if (numberOfSubscribers && numberOfSubscribers < 0) {
      setNumberOfSubscribers(0);
    }
  }, [numberOfSubscribers]);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      toast.error("Start date cannot be after end date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    if (startDate && new Date(newEndDate) < new Date(startDate)) {
      toast.error("End date cannot be before start date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setEndDate(newEndDate);
    }
  };

  const handleEditDeadlineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEditDeadline = e.target.value;
    if (endDate && new Date(newEditDeadline) > new Date(endDate)) {
      toast.error("Edit Deadline date cannot be after end date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else if (startDate && new Date(newEditDeadline) < new Date(startDate)) {
      toast.error("Edit Deadline cannot be before start date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setEditDeadline(newEditDeadline);
    }
  };

  const handleBackClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setTabChange("general");
  };

  const handleSubscriptionStartDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newStartDate = e.target.value;
    if (
      subscriptionEndDate &&
      new Date(newStartDate) > new Date(subscriptionEndDate)
    ) {
      toast.error("Start date cannot be after end date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setSubscriptionStartDate(newStartDate);
    }
  };

  const handleSubscriptionEndDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEndDate = e.target.value;
    if (
      subscriptionStartDate &&
      new Date(newEndDate) < new Date(subscriptionStartDate)
    ) {
      toast.error("End date cannot be before start date!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } else {
      setSubscriptionEndDate(newEndDate);
    }
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (contractId !== undefined) {
      formData.append("id", contractId);
    }

    if (tabChange === "general") {
      formData.append("name", name);
      formData.append("terms", terms);

      if (startDate !== undefined) {
        formData.append("start_date", startDate);
      }
      if (endDate !== undefined) {
        formData.append("end_date", endDate);
      }
      if (editDeadline !== undefined) {
        formData.append("edit_deadline", editDeadline);
      }
    } else if (tabChange === "addlevel") {
      if (selectedLevel === "-1") {
        toast.error("Select a Level!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("level_id", selectedLevel);
      }
      if (numberOfLevelStudents !== null) {
        if (numberOfLevelStudents <= 0) {
          toast.error("Number of students must be a positive number!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append(
            "number_of_students",
            numberOfLevelStudents.toString()
          );
        }
      }
      if (LevelPrice !== null) {
        if (LevelPrice <= 0) {
          toast.error("Price must be a positive number!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append("price", LevelPrice.toString());
        }
      }
    } else if (tabChange === "editlevel") {
      formData.append("contract_level_id", current_contract_level_edit);
      if (numberOfLevelStudents !== null) {
        formData.append("number_of_students", numberOfLevelStudents.toString());
      }
      if (LevelPrice !== null) {
        formData.append("total_price", LevelPrice.toString());
      }
    } else if (tabChange === "addsubscription") {
      if (selectedSubscription === "-1") {
        toast.error("Select a Plan!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
        return;
      } else {
        formData.append("plan_id", selectedSubscription);
      }
      if (numberOfSubscribers !== null) {
        if (numberOfSubscribers <= 0) {
          toast.error("Number of subscribers must be a positive number!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "dark",
          });
          return;
        } else {
          formData.append(
            "subscriptions_purchased",
            numberOfSubscribers.toString()
          );
        }
      }
      if (subscriptionStartDate !== undefined) {
        formData.append("start_date", subscriptionStartDate);
      }
      if (subscriptionEndDate !== undefined) {
        formData.append("end_date", subscriptionEndDate);
      }
    } else if (tabChange === "editsubscription") {
      formData.append("subscription_id", current_contract_subscription_edit);
      if (numberOfSubscribers !== null) {
        formData.append(
          "subscriptions_purchased",
          numberOfSubscribers.toString()
        );
      }
      if (subscriptionStartDate !== undefined) {
        formData.append("start_date", subscriptionStartDate);
      }
      if (subscriptionEndDate !== undefined) {
        formData.append("end_date", subscriptionEndDate);
      }
      if (activeSubscription) {
        formData.append("plan_id", activeSubscription.subscription_plan_id);
      }
    }

    try {
      let endpoint = "";

      if (tabChange === "general") {
        endpoint = `${process.env.REACT_APP_EDIT_CONTRACT_GENERAL}${contractId}`;
      } else if (tabChange === "addlevel") {
        endpoint = `${process.env.REACT_APP_CREATE_CONTRACT_LEVEL}${contractId}`;
      } else if (tabChange === "editlevel") {
        endpoint = `${process.env.REACT_APP_EDIT_CONTRACT_LEVEL}${current_contract_level_edit}`;
      } else if (tabChange === "addsubscription") {
        endpoint = `${process.env.REACT_APP_CREATE_SCHOOL_SUBSCRIPTION}${schoolId}`;
      } else if (tabChange === "editsubscription") {
        endpoint = `${process.env.REACT_APP_EDIT_SCHOOL_SUBSCRIPTION}${current_contract_subscription_edit}`;
      }

      await api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      handleTabClick("general");
      setRefreshData((prevRefresh: any) => prevRefresh + 1);

      // window.location.href = `/contracts`;
      toast.success("Contract has been edited successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "dark",
      });
    } catch (error) {
      if (error instanceof Error) {
        // You can check for specific error properties or just use a default message
        const errorMessage =
          (error as any)?.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      } else {
        toast.error("An error occurred while editing Contract", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "dark",
        });
      }

      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative">
        <div className="absolute">
          <Link
            onClick={handleBackClick}
            state={{ school_id: schoolId }}
            to={
              tabChange === "general"
                ? `/schools/edit_school/${school_name}`
                : `/schools/edit_school/${school_name}/edit_contract/${contract_name}`
            }
          >
            <div className="flex flex-row items-center">
              <IoChevronBackSharp title="school" />
              <p>Go Back</p>
            </div>
          </Link>
        </div>
        <p className="text-[25px] text-center content-center pb-4">
          {tabChange === "addlevel"
            ? "Add Contract Grade"
            : tabChange === "addsubscription"
            ? `Add Subscription for ${contractData?.name}`
            : tabChange === "editsubscription"
            ? `Edit Subscription for ${contractData?.name}`
            : tabChange === "editlevel"
            ? `Edit Contract Grade for ${contractData?.name}`
            : `Edit ${tabChange} Section for ${contractData?.name} Contract`}
        </p>
        <div className="flex flex-col justify-between gap-4 w-full mb-16">
          {loading1 === true ? (
            <div className="w-full flex justify-center items-center pt-16">
              <ImSpinner2 size={25} className="animate-spin" />
            </div>
          ) : (
            <>
              {tabChange === "general" ? (
                <div className="flex flex-col">
                  <form className="w-full" onSubmit={handleEditSubmit}>
                    <div className="flex flex-col gap-4">
                      <div className="grid grid-cols-1 gap-2">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Contract Name:
                          </label>
                          <input
                            required
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                        <div className=" flex flex-col ">
                          <label className="pb-2 text-[20px]">
                            Total Price:
                          </label>
                          <input
                            disabled
                            type="number"
                            value={price}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">Terms:</label>
                          <JoditEditor
                            ref={editor}
                            config={config}
                            value={terms}
                            onChange={setTerms}
                          />
                        </div>

                        <div className="grid grid-cols-2 mt-12">
                          <div className="flex flex-col">
                            <label className="pb-2 text-[20px]">
                              Start Date:
                            </label>
                            <input
                              required
                              type="date"
                              value={startDate}
                              onChange={handleStartDateChange}
                              className="border-[2px] text-black outline-none p-1 rounded-lg"
                            />
                          </div>
                          <div className="flex flex-col">
                            <label className="pb-2 text-[20px]">
                              End Date:
                            </label>
                            <input
                              required
                              type="date"
                              value={endDate}
                              onChange={handleEndDateChange}
                              className="border-[2px] text-black outline-none p-1 rounded-lg"
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-2 mt-12">
                          <div className="flex flex-col">
                            <label className="pb-2 text-[20px]">
                              Edit Deadline:
                            </label>
                            <input
                              required
                              type="date"
                              value={editDeadline}
                              onChange={handleEditDeadlineChange}
                              className="border-[2px] text-black outline-none p-1 rounded-lg"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 flex justify-center">
                        <button
                          type="submit"
                          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className=" w-full border-b-2 mb-10 mt-10 border-black"></div>
                  <div className="w-full mb-10">
                    <div className="flex flex-row justify-between mb-5">
                      <label className="pb-2 text-[20px]">Grades:</label>
                      <button
                        onClick={() => handleTabClick("addlevel")}
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300"
                      >
                        Add Contract Grade
                      </button>
                    </div>
                    {activeLevelsData && activeLevelsData.length > 0 ? (
                      <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                        <table className="w-full text-sm text-center">
                          <thead className="border-b bg-gray-300">
                            <tr>
                              <th scope="col" className="px-6 py-3 w-20">
                                ID
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Grade
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Maximum Number Of Seats
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Assigned Seats
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Remaining Seats
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Price Per Student
                              </th>
                              <th scope="col" className="px-6 py-3 w-[180px]">
                                Grade Management
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeLevelsData.map((level, index) => (
                              <tr key={index} className="bg-gray-200 border-b">
                                <td className="px-6 py-4">
                                  {level.contract_level_id}
                                </td>
                                <td className="px-6 py-4">
                                  {level.level_name}
                                </td>
                                <td className="px-6 py-4">
                                  {level.number_of_students}
                                </td>
                                <td className="px-6 py-4">
                                  {level.assigned_seats}
                                </td>
                                <td className="px-6 py-4">
                                  {level.remaining_seats}
                                </td>
                                <td className="px-6 py-4">
                                  {level.total_level_price}$
                                </td>

                                <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                                  <button
                                    onClick={() => {
                                      current_contract_level_edit =
                                        level.contract_level_id;
                                      handleTabClick("editlevel");
                                    }}
                                    className="bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                                  >
                                    <BiEdit />
                                  </button>

                                  <button
                                    onClick={() =>
                                      openDeleteContractLevel(index)
                                    }
                                    className="bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                                  >
                                    <MdDelete />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                  <div className=" w-full border-b-2 mb-10 mt-10 border-black"></div>
                  <div className="w-full mb-10 ">
                    <div className="flex flex-row justify-between mb-5">
                      <label className="pb-2 text-[20px]">Subscriptions:</label>
                      <button
                        onClick={() => handleTabClick("addsubscription")}
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300"
                      >
                        Add Subscription
                      </button>
                    </div>
                    {activeSubscriptionsData &&
                    activeSubscriptionsData.length > 0 ? (
                      <div className="overflow-x-auto shadow-md sm:rounded-lg w-full">
                        <table className="w-full text-sm text-center">
                          <thead className="border-b bg-gray-300">
                            <tr>
                              <th scope="col" className="px-6 py-3 w-20">
                                ID
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Name
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Subscriptions Purchased
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Active Subscriptions
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Price
                              </th>
                              {/* <th scope="col" className="px-6 py-3">
                                Status
                              </th> */}
                              <th scope="col" className="px-6 py-3 w-[180px]">
                                Subscription Management
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeSubscriptionsData.map(
                              (subscription, index) => (
                                <tr
                                  key={index}
                                  className="bg-gray-200 border-b"
                                >
                                  <td className="px-6 py-4">
                                    {subscription.id}
                                  </td>
                                  <td className="px-6 py-4">
                                    {subscription.subscription_plan}
                                  </td>
                                  <td className="px-6 py-4">
                                    {subscription.subscriptions_purchased}
                                  </td>
                                  <td className="px-6 py-4">
                                    {subscription.active_subscriptions}
                                  </td>
                                  <td className="px-6 py-4">
                                    {subscription.total_plan_price}$
                                  </td>
                                  {/* <td className="px-6 py-4">
                                    {subscription.status}
                                  </td> */}
                                  <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                                    <button
                                      onClick={() => {
                                        current_contract_subscription_edit =
                                          subscription.id;
                                        handleTabClick("editsubscription");
                                      }}
                                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                                    >
                                      <BiEdit />
                                    </button>

                                    <button
                                      onClick={() =>
                                        openDeleteContractSubscription(index)
                                      }
                                      className="bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                                    >
                                      <MdDelete />
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {tabChange === "addlevel" ? (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="flex flex-col">
                        <label className="pb-2 text-[20px]">Grade:</label>
                        <Select
                          value={selectedLevel}
                          onChange={(e) => {
                            setSelectedLevel(e.target.value);
                          }}
                          className=" w-60"
                        >
                          <MenuItem value="-1" disabled>
                            Select Grade
                          </MenuItem>
                          {levelsData &&
                            levelsData.map((level, index) => (
                              <MenuItem
                                id="tone-div"
                                key={index}
                                value={level.id}
                                disabled={level.status === "Disabled"}
                              >
                                {level.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                      <div className=" flex flex-col mt-4">
                        <label className="pb-2 text-[20px]">
                          Number Of Students:
                        </label>
                        <input
                          required
                          type="number"
                          value={numberOfLevelStudents ?? ""}
                          onChange={(e) =>
                            setNumberOfLevelStudents(
                              e.target.value ? parseFloat(e.target.value) : null
                            )
                          }
                          className="border-[2px] text-black outline-none p-1 rounded-lg w-60"
                        />
                      </div>
                      <div className=" flex flex-col mt-3">
                        <label className="pb-2 text-[20px]">
                          Price Per Student:
                        </label>
                        <input
                          type="number"
                          value={LevelPrice ?? ""}
                          onChange={(e) =>
                            setLevelPrice(
                              e.target.value ? parseFloat(e.target.value) : null
                            )
                          }
                          className="border-[2px] text-black outline-none p-1 rounded-lg w-60"
                        />
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Add Contract Grade
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "editlevel" ? (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="flex flex-col">
                        <label className="pb-2 text-[20px]">Grade Name:</label>
                        <input
                          disabled
                          type="text"
                          value={editLevelName}
                          className="border-[2px] text-black outline-none p-1 rounded-lg w-60"
                        />
                      </div>

                      <div className=" flex flex-col mt-3">
                        <label className="pb-2 text-[20px]">
                          Number Of Students:
                        </label>
                        <input
                          required
                          type="number"
                          value={numberOfLevelStudents ?? ""}
                          onChange={(e) =>
                            setNumberOfLevelStudents(
                              e.target.value ? parseFloat(e.target.value) : null
                            )
                          }
                          className="border-[2px] text-black outline-none p-1 rounded-lg w-60"
                        />
                      </div>
                      <div className=" flex flex-col mt-3">
                        <label className="pb-2 text-[20px]">
                          Price Per Student:
                        </label>
                        <input
                          type="number"
                          value={LevelPrice ?? ""}
                          onChange={(e) =>
                            setLevelPrice(
                              e.target.value ? parseFloat(e.target.value) : null
                            )
                          }
                          className="border-[2px] text-black outline-none p-1 rounded-lg w-60"
                        />
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "addsubscription" ? (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="grid grid-cols-2 mt-5 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Subscription:
                          </label>
                          <Select
                            value={selectedSubscription}
                            onChange={(e) => {
                              setSelectedSubscription(e.target.value);
                            }}
                            className=""
                          >
                            <MenuItem value="-1" disabled>
                              Select Subscription
                            </MenuItem>
                            {subscriptionsData &&
                              subscriptionsData.map((subscription, index) => (
                                <MenuItem
                                  id="tone-div"
                                  key={index}
                                  value={subscription.id}
                                  disabled={subscription.status === "Disabled"}
                                >
                                  {subscription.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                        <div className=" flex flex-col ">
                          <label className="pb-2 text-[20px]">
                            Number Of Subscribers:
                          </label>
                          <input
                            required
                            type="number"
                            value={numberOfSubscribers ?? ""}
                            onChange={(e) =>
                              setNumberOfSubscribers(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg h-full"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 mt-2 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Start Date:
                          </label>
                          <input
                            required
                            type="date"
                            value={subscriptionStartDate}
                            onChange={handleSubscriptionStartDateChange}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">End Date:</label>
                          <input
                            required
                            type="date"
                            value={subscriptionEndDate}
                            onChange={handleSubscriptionEndDateChange}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Add Subscription
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabChange === "editsubscription" ? (
                <form className="w-full" onSubmit={handleEditSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 gap-2">
                      <div className="grid grid-cols-2 mt-2 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Subscription Name:
                          </label>
                          <input
                            disabled
                            type="text"
                            value={editSubscriptionName}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                        <div className=" flex flex-col ">
                          <label className="pb-2 text-[20px]">
                            Total Price:
                          </label>
                          <input
                            disabled
                            type="number"
                            value={editSubscriptionPrice}
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 mt-2 gap-5">
                        <div className=" flex flex-col ">
                          <label className="pb-2 text-[20px]">
                            Number Of Subscribers:
                          </label>
                          <input
                            required
                            type="number"
                            value={numberOfSubscribers ?? ""}
                            onChange={(e) =>
                              setNumberOfSubscribers(
                                e.target.value
                                  ? parseFloat(e.target.value)
                                  : null
                              )
                            }
                            className="border-[2px] text-black outline-none p-1 rounded-lg "
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 mt-2 gap-5">
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">
                            Start Date:
                          </label>
                          <input
                            required
                            type="date"
                            value={subscriptionStartDate}
                            onChange={handleSubscriptionStartDateChange}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="pb-2 text-[20px]">End Date:</label>
                          <input
                            required
                            type="date"
                            value={subscriptionEndDate}
                            onChange={handleSubscriptionEndDateChange}
                            className="border-[2px] text-black outline-none p-1 rounded-lg"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <button
                        type="submit"
                        className="bg-[#4D30B5] hover:bg-[#1b1436] text-white px-5 py-3 rounded duration-300"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}
            </>
          )}
          {isDeleteContractLevelOpen && (
            <DeleteContractLevel
              handleDeleteClick={handleDeleteLevelClick}
              closeDeleteContractLevel={closeDeleteContractLevel}
              idEdit={activeLevelsData[currentIdDatabase].contract_level_id}
              nameEdit={activeLevelsData[currentIdDatabase].level_name}
              setRefresh={setRefresh}
              setRefreshData={setRefreshData}
            />
          )}
          {isDeleteContractSubscriptionOpen && (
            <DeleteContractSubscription
              handleDeleteClick={handleDeleteSubscriptionClick}
              closeDeleteContractSubscription={closeDeleteContractSubscription}
              idEdit={activeSubscriptionsData[currentIdDatabase].id}
              nameEdit={
                activeSubscriptionsData[currentIdDatabase].subscription_plan
              }
              setRefresh={setRefresh}
              setRefreshData={setRefreshData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default EditContract;
