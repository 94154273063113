import api from "./api";

export async function fetchData(url: string, setData: Function) {
  try {
    const response = await api.get(url);
    const data = response.data;
    setData(data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
