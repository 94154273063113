import { useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import { fetchData } from "../../../../GlobalFunctions";
import { ImSpinner } from "react-icons/im";
import AddLevel from "./AddLevel";
import DeleteLevel from "./DeleteLevel";
import { MdDelete } from "react-icons/md";

let currentIdDatabase = 0;

function Levels({ setRefreshData }: any) {
  interface LevelData {
    id: string;
    name: string;
    price: number;
  }

  const [levelsData, setLevelsData] = useState<LevelData[] | null>(null);
  const [refresh, setRefresh] = useState(0);
  const [isDeleteLevelOpen, setIsDeleteLevelOpen] = useState(false);
  const [isLevelOpen, setIsLevelOpen] = useState(false);

  const openDeleteLevel = async (id: number) => {
    currentIdDatabase = id;
    setIsDeleteLevelOpen(true);
  };

  const closeDeleteLevel = () => {
    setIsDeleteLevelOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeDeleteLevel();
    }
  };

  const openLevel = () => {
    setIsLevelOpen(true);
  };

  const handleLevelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeLevel();
    }
  };

  const closeLevel = () => {
    setIsLevelOpen(false);
  };

  useEffect(() => {
    setIsDeleteLevelOpen(false);
    fetchData(
      `${process.env.REACT_APP_API}${process.env.REACT_APP_GET_ALL_LEVELS}`,
      setLevelsData
    );
  }, [refresh, setRefreshData]);

  return (
    <div>
      <div className="flex justify-end mb-3">
        <button
          onClick={openLevel}
          className="bg-[#4D30B5] hover:bg-[#1b1436] text-white py-2 px-4 rounded duration-300 "
        >
          Create Grade
        </button>
      </div>
      {levelsData && levelsData.length > 0 ? (
        <div className=" overflow-x-auto shadow-md sm:rounded-lg mb-24">
          <table className="w-full text-sm text-center">
            <thead className="border-b bg-gray-300">
              <tr>
                <th scope="col" className="px-6 py-3 w-20">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Grade
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3 w-[180px]">
                  Grade management
                </th>
              </tr>
            </thead>
            <tbody>
              {levelsData?.map((level, index) => (
                <tr key={index} className="bg-gray-200 border-b ">
                  <td className="px-6 py-4">{level.id}</td>
                  <td className="px-6 py-4">{level.name}</td>
                  <td className="px-6 py-4">{level.price}$</td>
                  <td className="px-6 py-4 flex flex-row gap-2 w-40 justify-center">
                    <Link
                      state={{ level_id: level.id }}
                      to={`/grades/edit_grade/${level.name}`}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <BiEdit />
                    </Link>
                    <button
                      onClick={() => openDeleteLevel(index)}
                      className=" bg-[#4D30B5] hover:bg-[#1b1436] text-white p-2 rounded duration-300"
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center">
          <ImSpinner size={30} className=" animate-spin" />
        </div>
      )}
      {isLevelOpen && (
        <AddLevel
          handleLevelClick={handleLevelClick}
          closeLevel={closeLevel}
          setRefresh={setRefresh}
          setRefreshData={setRefreshData}
        />
      )}
      {levelsData && isDeleteLevelOpen && (
        <DeleteLevel
          handleDeleteClick={handleDeleteClick}
          closeDeleteLevel={closeDeleteLevel}
          idEdit={levelsData[currentIdDatabase].id}
          nameEdit={levelsData[currentIdDatabase].name}
          setRefresh={setRefresh}
          setRefreshData={setRefreshData}
        />
      )}
    </div>
  );
}

export default Levels;
