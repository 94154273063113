import { Outlet } from "react-router";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../Navigations/NavBar";
import SideMenu from "../Navigations/SideMenu";
import { useState } from "react";
import { IoIosMenu } from "react-icons/io";
import { AsyncImage } from "loadable-image";
import { BiUserCircle } from "react-icons/bi";
import { Blur } from "transitions-kit";
import Boxmind from "../../Assets/boxMind_wide_white.png";

function Dashboard({
  userData,
  setUserData,
  setUserID,
  changeSideActive,
  sideActive,
  mainSidebarOpen,
  changeMainSidebarOpen,
}: any) {
  const location = useLocation();

  return (
    <div>
      <nav className=" flex flex-row justify-between items-center bg-[#20144C] border-b border-[#C5D4EB] p-4 h-[71px]">
        <div className="h-[60px] ml-10">
          <div className=" flex items-center justify-center">
            <Link to={"/"}>
              <img
                src={Boxmind}
                alt="Boxmind"
                width={150}
                className="pt-2 -ml-5"
                loading="lazy"
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-row gap-10 max-sm:gap-2 items-center">
          <div className="flex flex-row gap-2 items-center text-[white]">
            <Link to={"/profile"}>
              <div>
                {userData?.profile_picture ? (
                  <AsyncImage
                    className="rounded-full w-[40px] h-[40px] object-cover"
                    src={`data:image/jpg;base64,${userData?.profile_picture}`}
                    alt={userData?.user_name}
                    style={{ width: 50, height: 50 }}
                    Transition={Blur}
                    loading="lazy"
                  />
                ) : (
                  <BiUserCircle className=" text-[35px] max-lg:text-[25px]" />
                )}
              </div>
            </Link>
            <div className=" font-semibold text-[16px] max-lg:hidden">
              {userData?.user_name}
            </div>
            <div className=" lg:hidden mt-1">
              <button onClick={() => changeSideActive()}>
                <IoIosMenu size={25} />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div
        className="h-screen lg:flex lg:flex-row -mt-[1px]"
        style={{ maxHeight: "calc(100vh - 70px)" }}
      >
        {sideActive === true && (
          <div className="lg:hidden fixed right-5 top-6 text-[#4D30B5]">
            <button onClick={() => changeSideActive()}>
              <IoIosMenu size={25} />
            </button>
          </div>
        )}
        <div
          className={`${
            mainSidebarOpen ? "lg:w-[17%]" : "lg:w-[5%]"
          }  bg-[#20144C] h-full duration-300 ${
            sideActive === false ? "max-lg:hidden" : ""
          }`}
        >
          <SideMenu
            changeSideActive={changeSideActive}
            sideActive={sideActive}
            userData={userData}
            setUserData={setUserData}
            setUserID={setUserID}
            changeMainSidebarOpen={changeMainSidebarOpen}
            mainSidebarOpen={mainSidebarOpen}
          />
        </div>
        <div className=" lg:col-span-6 h-screen flex flex-col w-full bg-[#F8FBFD]">
          <div className=" lg:grid grid-cols-11 h-full overflow-y-scroll custom-scrollbar-chat p-4  bg-[#F8FBFD]">
            <div id="parent" className=" col-span-11">
              <div
                className="flex flex-col h-screen "
                style={{ maxHeight: "calc(100vh - 105px)" }}
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
